import { z } from 'zod'
import { fieldClassEnumSchema } from '.'

const attributeSchema = z.object({
  field_class: fieldClassEnumSchema,
  name: z.string(),
  readonly: z.boolean().optional(),
  required: z.boolean().optional(),
  type: z.string().optional(),
})
type AttributeType = z.infer<typeof attributeSchema>
const startAttributeSchema = z.object({
  attribute: z.string(),
  field_class: fieldClassEnumSchema,
  label: z.string(),
  type: z.string().optional(),
})
type StartAttributeType = z.infer<typeof startAttributeSchema>

const workflowDetailsSchema = z.object({
  content: z.string().nullable(),
  description: z.string(),
  enabled: z.boolean(),
  id: z.string(),
  name: z.string(),
  optional_attributes: z.array(attributeSchema),
  optional_fields: z.array(z.string()),
  published: z.boolean(),
  required_attributes: z.array(attributeSchema),
  required_fields: z.array(z.string()),
  start_attributes: z.array(startAttributeSchema),
  version: z.literal('0.1.0'),
  waypoints: z.array(z.any()),
})
type WorkflowDetailsType = z.infer<typeof workflowDetailsSchema>

const workflowDetailsResponseSchema = z.discriminatedUnion('success', [
  z.object({
    message: workflowDetailsSchema,
    success: z.literal(true),
  }),
  z.object({
    message: z.string(),
    success: z.literal(false),
  }),
])

export {
  attributeSchema,
  startAttributeSchema,
  workflowDetailsSchema,
  workflowDetailsResponseSchema,
}
export type { AttributeType, StartAttributeType, WorkflowDetailsType }
