import { Link } from '@tanstack/react-router'
import { Popover } from '@headlessui/react'

import type { WorkflowType } from '@types'
import { Button, Chip } from '@components'

import permissionsTooltip from '@assets/permissions-tooltip.svg'
import arrow from '@assets/popover-arrow.svg'

// TODO: Verify / remove
type Statuses =
  | 'working'
  | 'error'
  | 'canceled'
  | 'complete'
  | 'stopped'
  | 'none'
const borderColor: Record<Statuses, string> = {
  none: '',
  working: 'shadow-primary',
  error: 'shadow-red-500',
  canceled: 'shadow-red-500',
  complete: 'shadow-green-400',
  stopped: '', // TODO: Get color
}

function isElementInLeftViewport(id: string | undefined) {
  const panel = document.querySelector('#panel-' + id)
  if (panel) {
    const rect = panel.getBoundingClientRect()
    return rect.left <= 0
  }
  return false
}

function reduceStatuses(status: string | undefined): Statuses {
  if (
    status === 'working' ||
    status === 'error' ||
    status === 'canceled' ||
    status === 'complete' ||
    status === 'stopped'
  ) {
    return status
  }
  return 'none'
}

type Props = {
  workflow: WorkflowType
  loanNumber: string
}
export default function WorkflowCard({ workflow, loanNumber }: Props) {
  const { source_id, status } = workflow.running_flows[0] || {}
  const _status = reduceStatuses(status)
  return (
    <div
      key={workflow.id}
      className={
        'relative flex min-h-[10rem] flex-col items-start justify-between rounded p-4 shadow-sm ' +
        borderColor[_status]
      }
    >
      <h5 className="w-full pb-2 pt-4 text-sm font-bold">{workflow.name}</h5>
      {!!status && <Chip status={status} size="xs" style="uppercase" />}
      {!workflow.available_to_order && (
        <div className="flex w-full origin-top-right pb-2 pt-2 text-sm text-gray-500">
          You have limited access
          <Popover className="relative">
            {() => {
              const isOverflowing = isElementInLeftViewport(workflow.id)
              return (
                <>
                  <Popover.Panel
                    id={'panel-' + workflow.id}
                    className={`${
                      isOverflowing ? '-left-1' : '-right-2'
                    } absolute bottom-5 z-10 mb-5 border-transparent`}
                  >
                    <p className="border-5 line-clamp-3 w-96 rounded border-solid bg-secondary p-3 text-left text-sm text-white shadow-sm">
                      Your current permission settings do not allow you to run
                      the Automation workflow. Please contact your
                      organization&apos;s manager to get access to this feature.
                    </p>
                    <img
                      className={`${
                        isOverflowing ? 'left-3' : 'right-3'
                      } absolute -bottom-2`}
                      src={arrow}
                    ></img>
                  </Popover.Panel>
                  <Popover.Button className="ml-1">
                    <img src={permissionsTooltip}></img>
                  </Popover.Button>
                </>
              )
            }}
          </Popover>
        </div>
      )}
      <span className="mb-2 mt-8 inline-flex w-full justify-center">
        <Link
          to={
            status
              ? '/additional-services/workflows/$loanNumber/$sourceId'
              : '/additional-services/workflows/order/$workflowId'
          }
          params={
            status
              ? {
                  loanNumber,
                  sourceId: source_id || '',
                }
              : {
                  workflowId: workflow.id,
                }
          }
          search={{
            disableBack: true,
          }}
        >
          <Button
            size="sm"
            variant="outlined"
            disabled={!status && !workflow.available_to_order}
          >
            Next
          </Button>
        </Link>
      </span>
    </div>
  )
}
