import type { AdditionalPropsType } from '@types'

export const loanTypeProps: AdditionalPropsType = {
  options: [
    {
      name: 'Home Equity',
    },
    {
      name: 'Refinance',
    },
    {
      name: 'Purchase',
    },
    {
      name: 'Construction Loan',
    },
    {
      name: 'Other',
    },
  ],
}
