import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import errorSvg from '@assets/x-circle.svg'

type AlertDialogProps = {
  isOpen: boolean
  onClose: () => void
}

export function AlertDialog({ isOpen, onClose }: AlertDialogProps) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0 top-[64px] overflow-y-auto">
          <div className="flex items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="flex w-full max-w-lg transform items-center overflow-hidden rounded-md bg-red-100 p-2 transition-all">
                <img src={errorSvg} className="mr-2 h-5 w-5" />
                <Dialog.Title
                  as="div"
                  className="inline-flex items-center text-base font-medium text-red-900"
                >
                  An error occurred:
                  <p className="ml-2 mr-1 font-normal">
                    Please contact support at
                  </p>
                  <a href="tel:855-525-6730" className="underline">
                    855-525-6730
                  </a>
                </Dialog.Title>

                <div className="-mb-1 -mt-1 ml-auto">
                  <button
                    type="button"
                    className="rounded p-1 text-red-500 transition-colors hover:bg-red-200"
                    onClick={onClose}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                      className="h-4 w-4"
                    >
                      <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                    </svg>
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
