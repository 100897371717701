import { fetchInteractiveNode, fetchWorkflowStatus } from '@services/workflows'
import { useRouter } from '@tanstack/react-router'
import type {
  NodeType,
  WorkflowInteractiveType,
  WorkflowStatusType,
} from '@types'
import { useEffect } from 'react'
import { workflowStatusRoute } from '../../router/authenticated/additionalServicesRoute/workflowRoutes'

export type WorkflowStatusStateType = {
  data: WorkflowStatusType
  info?: WorkflowInteractiveType
}

const nodesToFilter = [
  'start workflow',
  'complete workflow',
  'cancel workflow',
  'tech fee',
  'push state',
]

function filterWorkflowNodes(data?: NodeType[]) {
  return data
    ?.sort((a, b) => {
      if (a.date_started && b.date_started) {
        return b.date_started.getTime() - a.date_started.getTime()
      }
      return 0
    })
    .filter(n => n.status !== 'waiting' && !nodesToFilter.includes(n.node))
}

/**
 * Refetch workflow status & info data every
 * 7 seconds until the workflow is finished
 *
 * @requires RouterProvider as a parent
 */
function usePollWorkflowStatus(
  setData: React.Dispatch<React.SetStateAction<WorkflowStatusStateType>>,
  data: WorkflowStatusType,
  info?: WorkflowInteractiveType
) {
  const router = useRouter()
  const { loanNumber, sourceId } = workflowStatusRoute.useParams()
  useEffect(() => {
    const interval = setInterval(() => {
      // We may want to handle this differently if we notice problems,
      // like state getting reset, or things like that...
      Promise.all([
        fetchWorkflowStatus(loanNumber, sourceId),
        fetchInteractiveNode(data.id),
      ])
        .then(([newData, newInfo]) => {
          let nextData: WorkflowStatusType | null = null
          let nextInfo: WorkflowInteractiveType | null = null
          if (
            newData &&
            !(newData instanceof Response || typeof newData === 'string')
          ) {
            nextData = newData
          }
          if (newInfo && !(newInfo instanceof Response)) {
            nextInfo = newInfo
          }
          setData(prev => {
            const next = {
              data: nextData ?? prev.data,
              info: nextInfo ?? undefined,
            }
            console.info('Setting data\n', { prev, next })
            return next
          })
          if (nextData?.completed_date != null) clearInterval(interval)
          if (nextData?.canceled_date != null) clearInterval(interval)
        })
        .catch(e => console.error(e))
    }, 7000)

    // if the workflow is completed, stop polling
    if (data?.completed_date != null) clearInterval(interval)
    if (data?.canceled_date != null) clearInterval(interval)
    // On unmount clear interval
    return () => {
      clearInterval(interval)
    }
  }, [router, info, data, setData, loanNumber, sourceId])
}

export { filterWorkflowNodes, usePollWorkflowStatus }
