function isObj(obj: unknown): obj is Record<string, unknown> {
  return typeof obj === 'object' && obj !== null
}

export function convertNestedObjectToDotNotation(
  obj: unknown,
  prefix: string = ''
): Record<string, unknown> | null {
  if (!isObj(obj)) return null
  return Object.keys(obj).reduce<Record<string, unknown>>((acc, key) => {
    const nestedKey = prefix ? `${prefix}.${key}` : key

    if (
      typeof obj[key] === 'object' &&
      !Array.isArray(obj[key]) &&
      obj[key] !== null
    ) {
      const nestedObject = convertNestedObjectToDotNotation(obj[key], nestedKey)
      return { ...acc, ...nestedObject }
    }

    return { ...acc, [nestedKey]: obj[key] }
  }, {})
}
