import { useEffect, useState } from 'react'
import { useRouter } from '@tanstack/react-router'

import {
  type NodeType,
  type WorkflowStatusType,
  workflowOverrideNodePostSchema,
} from '@types'
import { postOverrideNode, postCancel } from '@services/workflows'
import { Button, Chip } from '@components'

import checkSvg from '@assets/check-circle.svg'
import exclaimSvg from '@assets/exclaim-circle.svg'
import errorSvg from '@assets/x-circle.svg'

import { workflowStatusRoute } from '../../router/authenticated/additionalServicesRoute/workflowRoutes'

export default function WorkflowStatusRow({
  nodeData,
  flowData,
}: {
  nodeData: NodeType
  flowData: WorkflowStatusType
}) {
  return (
    <li className="inline-flex w-full flex-row items-center gap-4">
      {/* row icon */}
      <RowIcon type={nodeData.status} />
      {/* row details */}
      <div className="flex w-full flex-col gap-2 rounded p-4 px-6 shadow-xs">
        {/* TODO: Add some active state */}

        <div className="flex w-full flex-row items-center gap-4 text-sm">
          {!!nodeData.status && (
            <div className="w-20">
              <Chip status={nodeData.status} />
            </div>
          )}
          {!!nodeData.order_id && (
            <p className="text-primary">
              <span className="text-secondary-light">Order ID </span>
              {nodeData.order_id}
            </p>
          )}
          <p className="font-medium">{nodeData.description}</p>
          <p className="ml-auto w-2/5 text-xs capitalize text-secondary-cold">
            {nodeData.node}
          </p>
        </div>
        <StatusRowOverride
          overrides={flowData.input_required}
          node={nodeData}
        />
      </div>
    </li>
  )
}

// REFACTOR: Code Split below

/**
 * @throws {TypeError} if attribute is undefined
 */
function getOverrideLabel(attribute?: string) {
  switch (attribute) {
    case 'override.flood_zone':
      return 'Property is in a flood zone, continue anyway?'
    case 'override.ltv':
      return 'Loan to value (LTV) does not meet requirements, continue anyway?'
    case undefined:
      throw new TypeError('Attribute provided was undefined')
    default:
      return attribute
  }
}

type AlertState = readonly [string | null, boolean]

function useAlert() {
  const r = useRouter()
  const [[message, hasMessage], setMessage] = useState<AlertState>([
    null,
    false,
  ])
  useEffect(() => {
    if (hasMessage) {
      throw new Error(message ?? 'Oops an error has occurred...')
    }
  }, [hasMessage, message, r])

  const handler = (res: Response | Record<string, unknown>) => {
    // Handle errors
    if (res instanceof Response) {
      setMessage([
        'An error has occured with a status code: ' + res.status,
        true,
      ])
    } else if ('success' in res && res.success === true) {
      console.info('No errors posting cancel node data')
      void r.invalidate()
    } else {
      setMessage([getResMessage(res), true])
    }
  }
  return [[message, hasMessage], handler] as const
}

// Kinda gross but better than it was...
function getResMessage(res: Record<string, unknown>) {
  if ('message' in res) {
    const msg = res.message
    if (
      msg &&
      typeof msg === 'object' &&
      'success' in msg &&
      typeof msg.success === 'string'
    ) {
      return msg.success
    } else if (typeof msg === 'string') {
      return msg
    }
  } else if (typeof res === 'string') {
    return res
  }
  // default (general error)
  return 'Response from coviance failed'
}

function StatusRowOverride({
  node,
  overrides,
}: {
  overrides?: WorkflowStatusType['input_required']
  node: NodeType
}) {
  const { data } = workflowStatusRoute.useLoaderData()
  const [[message, hasMessage], messageHandler] = useAlert()

  if (!overrides) return null

  const filtered = overrides.filter(o => o.node_id === node.id)
  if (!filtered.length) return null

  const overrideHandler = async () => {
    const data = workflowOverrideNodePostSchema.parse({
      version: 1,
      state: {
        [filtered[0]?.attribute || '']: true,
        version: '0.1.0',
      },
      node_id: filtered[0]?.node_id,
    })
    const res = await postOverrideNode(data)
    console.info('Override and continue')
    // Handle errors
    if (res) messageHandler(res)
  }

  const cancelHandler = async () => {
    if (!data || data instanceof Response || typeof data === 'string') return
    const res = await postCancel(data.id)
    // Handle errors
    messageHandler(res)
  }

  return (
    <>
      {hasMessage && (
        <div className="absolute left-0 top-28 inline-flex w-full justify-center">
          <p className="inline-flex w-fit items-center gap-2 rounded bg-red-100 p-2 px-4">
            <Icon />
            {message}
          </p>
        </div>
      )}
      <div className="relative my-2 flex flex-row items-center gap-4 rounded bg-red-100 p-4">
        <Icon />
        <p className="text-sm font-normal">
          {getOverrideLabel(filtered[0]?.attribute)}
        </p>
      </div>
      <div className="flex flex-row items-center gap-4">
        <Button
          size="md"
          onClick={() => void overrideHandler().catch(console.warn)}
        >
          Override & Continue
        </Button>
        <Button
          size="md"
          variant="text"
          className="text-red-500"
          onClick={() => void cancelHandler()}
        >
          Stop Workflow
        </Button>
      </div>
    </>
  )
}

// REFACTOR: Move to shared
function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 fill-red-600"
      viewBox="0 0 24 24"
    >
      <path d="M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
    </svg>
  )
}

// TODO: Verify Icons & Combine with shared chip component
const iconMap: Record<NodeType['status'], string> = {
  // None
  prepared: '',
  error_skipped: '',
  working: '',
  processing: '',
  skipped: '',
  waiting: '',
  // Check
  submit: checkSvg,
  complete: checkSvg,
  Complete: checkSvg,
  // Exclaim
  no_hit: exclaimSvg,
  canceled: exclaimSvg,
  need_info: exclaimSvg,
  in_research: exclaimSvg,
  'need input': exclaimSvg,
  'Inspection Scheduled': exclaimSvg,
  // Error
  'File Validation Error': errorSvg,
  stopped: errorSvg,
  error: errorSvg,
  connection_error: errorSvg,
}

function RowIcon({ type }: { type: NodeType['status'] }) {
  if (type === 'need input') return null
  if (type !== 'working') {
    return <img src={iconMap[type]} />
  }
  return (
    <div className="ml-1 aspect-square h-8 w-8 animate-pulse rounded-full border-4 border-dashed border-gray-300" />
  )
}
