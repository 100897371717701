import { CloseButton } from '@components'
import svgRedStop from '@assets/red-stop.svg'

const Error500 = ({ error }: { error: unknown }) => {
  if (!(error instanceof Error)) {
    console.warn('Unhandled error: \n', error)
    return null
  }

  if (error.message === '500') {
    return (
      <>
        <div className="mt-20">
          <div className="flex flex-col items-center gap-4">
            <img src={svgRedStop} />
            <div className="flex flex-col items-center gap-y-9">
              <p className="text-lg font-semibold text-black">
                We apologize, something went wrong on our side.
              </p>
              <p className="gap-3 text-base font-normal text-black">
                No worries, though! Our team has received the notification and
                is looking into the fix immediately.
              </p>

              <p className="gap-3 text-base font-normal text-black">
                For immediate assistance, call us directly at{' '}
                <a
                  href="tel:855-525-6730"
                  className="font-medium text-primary-hover"
                >
                  855-525-6730
                </a>{' '}
                or send us an email at{' '}
                <a
                  href="mailto:support@coviance.com"
                  className="font-medium text-primary-hover"
                >
                  support@coviance.com
                </a>
              </p>
              <CloseButton size="md" variant="primary" />
            </div>
            <div className="gap-4"></div>
          </div>
        </div>
      </>
    )
  }

  // This is for general errors
  return (
    <>
      <p className="my-4">{error.message}</p>

      <CloseButton size="md" variant="primary" />
    </>
  )
}

export default Error500
