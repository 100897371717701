import { useRef } from 'react'
import { type FieldError, useFormContext } from 'react-hook-form'

import { getNestedValue, prettify } from '@utils'
import type { FieldComponentPropsType } from '@types'

import { DISABLED_PROPS, useDataAttributes } from './_helpers'
import FieldErrorMessage from './FieldErrorMessage'
import FieldLabel from './FieldLabel'

/**
 * Shared Float Field Component
 *
 * @requires useForm using react-hook-form
 */
export default function FloatField({
  field,
  registerOptions,
}: FieldComponentPropsType) {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  const containerRef = useRef<HTMLDivElement>(null)
  const { onFocus, onLabelClick } = useDataAttributes(containerRef, field.name)

  const fieldLabel = field.label || prettify(field.name)
  const error = getNestedValue<FieldError>(errors, field.name)
  return (
    <div
      ref={containerRef}
      data-testid="field"
      data-invalid={error ? 'true' : 'false'}
      className="group w-full"
    >
      <div className="relative">
        <FieldLabel
          label={fieldLabel}
          required={field.required}
          onClick={onLabelClick}
        />
        <input
          type="number"
          onWheel={e => e.currentTarget.blur()}
          step="0.01"
          {...register(field.name, {
            required: field.required,
            ...registerOptions,
          })}
          onFocus={onFocus}
          autoComplete="off"
          aria-invalid={error ? 'true' : 'false'}
          {...(field.disabled === true ? DISABLED_PROPS : null)}
          className="transition-color w-full rounded-md border border-gray-400 p-3 text-sm disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-50 disabled:bg-opacity-50 disabled:text-gray-500 group-data-[invalid=true]:border-red-500 group-data-[invalid=true]:text-red-500 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
        />
      </div>
      <FieldErrorMessage label={fieldLabel} error={error} />
    </div>
  )
}
