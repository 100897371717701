import type {
  FieldPropsType,
  ConditionalFieldsType,
  WorkflowDetailsType,
  FieldsType,
} from '@types'
import { OriginContext, objectToDot } from '@utils'
import { useCallback, useContext, useMemo } from 'react'
import type {
  FieldError,
  Path,
  SubmitErrorHandler,
  UseFormReturn,
} from 'react-hook-form'
import {
  buildProductFields,
  buildWorkflowDetailFields,
  processConditionalFields,
} from './_helpers'
import { checkLoanData } from './checkLoanData'
import { processFormData } from './processFormData'
import { handleFormErrors } from '../handleFormErrors'

const DISABLED_ERROR = {
  type: 'disabled',
  message: 'Return to your application in encompass to edit this field.',
} as const

type FormFieldsDataType = WorkflowDetailsType | FieldsType | FieldPropsType[]

type FormType = Record<string, unknown>

const useFormFields = <T extends FormType>(options: {
  fieldData?: FormFieldsDataType
  /**
   * The form methods to hook into
   */
  methods?: UseFormReturn<T>

  /**
   * Any conditional fields to apply
   */
  conditionalFields?: ConditionalFieldsType

  /**
   * Hook in to the form data before it is submitted
   *
   * @returns Data passed into the onSubmit handler
   */
  beforeSubmit?: (d: T) => (T | void) | Promise<T | void>

  /**
   * Hook in to the form data after it is submitted
   */
  afterSubmit?: (d: T) => void | Promise<void>

  /**
   * The submit handler is passed into the methods.handleSubmit
   *
   * @param d This data is processed by processFormData
   * before being passed into the onSubmit handler
   *
   * @see processFormData
   *
   * @returns Data passed into the afterSubmit handler
   */
  onSubmit?: (d: T) => (T | void) | Promise<T | void>

  /**
   * Pass a custom error handler
   *
   * @default console.error
   */
  onError?: (e: T) => void
}) => {
  // Get the origin for loan data
  const origin = useContext(OriginContext)
  // Build the fields
  const fields = useMemo(() => {
    let result: FieldPropsType[]
    const fieldData = options.fieldData
    if (fieldData == null) result = []
    else {
      if ('start_attributes' in fieldData) {
        // Handle workflow detail (ordering) fields
        result = buildWorkflowDetailFields(fieldData)
      } else if (!Array.isArray(fieldData)) {
        // Handle product fields
        result = buildProductFields(fieldData)
      } else {
        // Handle raw field data
        result = fieldData
      }
    }

    return result
  }, [options.fieldData])

  // Process the fields
  const processedFields = useMemo(() => {
    let result: FieldPropsType[]

    // Check if we need to apply loan data to the fields
    result = checkLoanData<T>(
      fields,
      ((origin?.loan && objectToDot(origin.loan)) ?? {}) as T,
      options?.methods
    )

    // update the fields based on conditional fields
    result = processConditionalFields(result, options?.conditionalFields)

    // trigger the fields to update
    void options?.methods?.trigger()

    // Return the final form fields
    return result
  }, [fields, origin, options?.conditionalFields, options?.methods])

  const onInvalid = useCallback<SubmitErrorHandler<T>>(
    e => {
      const errors = handleFormErrors(e)
      const { methods, onError = console.error } = options || {}
      for (const err of errors) {
        const k = (Object.keys(err)[0] || '') as Path<T>
        const v = err[k] as FieldError
        if (fields.find(el => el.name === k)?.disabled) {
          console.debug(`Setting disabled error for ${k}`, v)
          methods?.setError(k, DISABLED_ERROR)
        }
      }

      // Custom error handler
      // This is in addition too default field errors
      onError(e)
    },
    [fields, options]
  )

  // Build the submit handler
  const submitHandler = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      const {
        methods,
        onSubmit = d => Promise.resolve(d),
        beforeSubmit = d => Promise.resolve(d),
        afterSubmit = d => Promise.resolve(d),
      } = options

      e.preventDefault()
      if (methods) {
        return void methods.handleSubmit(async formData => {
          // hook into beforeSubmit handler
          console.debug('beforeSubmit', formData)
          const before = await beforeSubmit(formData)

          // hook into the onSubmit handler
          console.debug('onSubmit', before || formData)
          const sub = await onSubmit(
            // always process the form data before submitting
            processFormData(before || formData, fields)
          )

          // hook into the afterSubmit handler
          console.debug('afterSubmit', sub || formData)
          await afterSubmit(sub || formData)
        }, onInvalid)()
      }
    },
    [fields, options, onInvalid]
  )

  return [processedFields, submitHandler, fields] as const
}

export { useFormFields, checkLoanData }
