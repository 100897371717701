import { prettify } from '@utils'
import { type StatusEnumType } from '@types'
import type { ReactNode } from 'react'

//#region Chip Helpers & Types
const bgColorMap: Record<StatusEnumType, string> = {
  // Brand (purples)
  working: 'bg-primary-light',
  processing: 'bg-primary-light',
  skipped: 'bg-primary-light',
  waiting: 'bg-primary-light',
  in_research: 'bg-primary-light',
  prepared: 'bg-primary-light',
  'Inspection Scheduled': 'bg-primary-light',
  // Greens
  complete: 'bg-status-green',
  Complete: 'bg-status-green',
  submit: 'bg-status-green',

  // Reds
  'need input': 'bg-status-red',
  need_info: 'bg-status-red',
  error_skipped: 'bg-status-red',
  stopped: 'bg-status-red',
  canceled: 'bg-status-red',
  no_hit: 'bg-status-red',
  error: 'bg-status-red',
  connection_error: 'bg-status-red',
  'File Validation Error': 'bg-status-red',
}

type ChipProps = {
  /**
   * The status to render, controls the default label & styles
   */
  status: StatusEnumType
  /**
   * For custom labels or icons
   */
  children?: ReactNode
  /**
   * These class names will be added to the default classNames,
   * providing you the ability to override any particular styles you might need to.
   * @default ''
   */
  className?: string
  /**
   * The size of the chip
   * @default 'sm'
   */
  size?: 'xs' | 'sm'
  /**
   * The font style of the chip
   * @default 'capitalize'
   */
  style?: keyof typeof fontStyleMap
}
type SizeKeys = Record<Exclude<ChipProps['size'], undefined>, string>

const fontSizeMap: SizeKeys = {
  xs: 'text-[10px]',
  sm: 'text-xs',
}
const lineHeightMap: SizeKeys = {
  xs: 'leading-none',
  sm: 'leading-tight',
}
const fontStyleMap = {
  none: '',
  capitalize: 'capitalize',
  uppercase: 'uppercase',
}

/**
 * Shared Chip Component
 */
export default function Chip({
  status,
  children,
  className = '',
  size = 'sm',
  style = 'uppercase',
}: ChipProps) {
  return (
    <p
      data-testid="chip"
      className={
        `rounded-full ${bgColorMap[status]} inline-flex max-w-fit items-center p-2 py-1 ${fontSizeMap[size]} text-secondary-default font-semibold ${fontStyleMap[style]} ${lineHeightMap[size]} ` +
        className
      }
    >
      {children || prettify(status)}
    </p>
  )
}
