import type { FieldPropsType } from '@types'
import { createContext, useContext } from 'react'

export const RawFieldsContext = createContext<FieldPropsType[] | undefined>(
  undefined
)

export const useRawFields = () => {
  const context = useContext(RawFieldsContext)
  if (context === undefined) {
    throw new Error('useRawFields must be used within a RawFieldsProvider')
  }
  return context
}
