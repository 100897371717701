import { ErrorComponent, createRoute } from '@tanstack/react-router'

import NoWorkflows from '../../../../pages/NoWorkflows'
import WorkflowsIndex from '../../../../pages/WorkflowIndex'
import WorkflowOrdering from '../../../../pages/WorkflowOrdering'
import WorkflowStatus from '../../../../pages/WorkflowStatus'
import WorkflowsRedirect from '../../../../pages/WorkflowsRedirect'

import { Pending, Error500 } from '@components'
import {
  fetchWorkflow,
  fetchInteractiveNode,
  fetchWorkflowStatus,
  fetchAllWorkflows,
} from '@services/workflows'

import WorkflowsLayout from './_layout'
import { additionalServicesRoute } from '..'

export const workflowsRoute = createRoute({
  getParentRoute: () => additionalServicesRoute,
  path: 'workflows',
  pendingComponent: Pending,
  component: WorkflowsLayout,
  validateSearch: (
    search: Record<string, unknown>
  ): Record<string, unknown> => {
    if (search?.disableBack) {
      return {
        disableBack: search.disableBack,
      }
    }
    return {}
  },
  errorComponent: Error500,
})

export const noWorkflowsRoute = createRoute({
  getParentRoute: () => workflowsRoute,
  path: 'no-workflows',
  component: NoWorkflows,
})

// TODO: remove this route? combine into workflowsRoute?
export const workflowsRedirectRoute = createRoute({
  getParentRoute: () => workflowsRoute,
  path: '/',
  component: WorkflowsRedirect,
  pendingComponent: Pending,
  errorComponent: ErrorComponent,
})
export const workflowsIndexRoute = createRoute({
  getParentRoute: () => workflowsRoute,
  path: '$loanNumber',
  validateSearch: s => s,
  component: WorkflowsIndex,
  loader: ({ params }) => fetchAllWorkflows(params.loanNumber),
  pendingComponent: Pending,
  errorComponent: Error500,
})

export const workflowOrderingRoute = createRoute({
  getParentRoute: () => workflowsRoute,
  path: 'order/$workflowId',
  loader: ({ params }) => fetchWorkflow(params.workflowId),
  pendingComponent: Pending,
  component: WorkflowOrdering,
  errorComponent: Error500,
})

export const workflowStatusRoute = createRoute({
  getParentRoute: () => workflowsRoute,
  path: '$loanNumber/$sourceId',
  loader: async ({ params }) => {
    const data = await fetchWorkflowStatus(params.loanNumber, params.sourceId)
    if (
      data instanceof Response ||
      data === undefined ||
      typeof data === 'string'
    ) {
      // There was an error.
      return { data, info: undefined }
    } else {
      const info = await fetchInteractiveNode(data.id)
      return { data, info }
    }
  },
  pendingComponent: Pending,
  component: WorkflowStatus,
  errorComponent: Error500,
})
