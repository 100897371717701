import { z } from 'zod'

const submitSearchSchema = z.object({
  success: z.boolean(),
  order: z.string().optional(), // For Product Submit Success
  workflow: z.string().optional(), // For Workflow Submit Success
  errors: z
    .array(
      z.object({
        description: z.string(),
      })
    )
    .optional(), // Used for product order errors (transaction response errors)
  info: z.string().optional(), // Please try again in a few minutes.
})

type SubmitSearchType = z.infer<typeof submitSearchSchema>

export { submitSearchSchema }
export type { SubmitSearchType }
