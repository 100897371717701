export default function CardSection({
  title,
  children,
  width = 'w-1/2',
  required = false,
}: {
  title: string
  children: React.ReactNode
  width?: 'w-1/2' | 'w-full'
  required?: boolean
}) {
  return (
    <div className="inline-flex w-full flex-row items-start">
      <h5 className="min-w-[10rem] text-sm font-medium text-gray-800">
        {title}
        {required && (
          <span className="align-super text-xs text-gray-500">*</span>
        )}
      </h5>
      <div
        className={`grid ${width} grid-cols-2 gap-x-8 gap-y-4 rounded-md p-8 ring-1 ring-gray-200 max-lg:w-full`}
      >
        {children}
      </div>
    </div>
  )
}
