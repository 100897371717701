import { authFetch } from '@services/authFetch'
import { workflowStatusResponseSchema } from '@types'

export const fetchWorkflowStatus = async (
  loan_number: string,
  source_id: string
) => {
  console.log(
    `Fetching workflow status for 
loan_number: ${loan_number}
source_id: ${source_id}`
  )
  const res = await authFetch(
    `/api/encompass/workflows?loan_number=${loan_number}&source_id=${source_id}`
  )
  if (res.ok) {
    const data = (await res.json()) as unknown
    const parsed = workflowStatusResponseSchema.parse(data).message
    return parsed
  } else if (res.status === 500) {
    return res
  }
}
