import { z } from 'zod'
import { statusEnumSchema } from './covianceStatuses'

const stateSchema = z.unknown().nullable()

const displayInfoSchema = z
  .object({
    description: z.string().optional(),
    content: z.record(z.unknown()).optional(),
    subHeading: z.string().optional().nullable(),
    title: z.string().optional().nullable(),
  })
  .optional()
  .nullable()

const nodeSchema = z.object({
  date_ended: z.coerce.date().nullable(),
  date_started: z.coerce.date().nullable(),
  decision_logs: z.array(z.unknown()).nullable(),
  description: z.string(),
  display_info: displayInfoSchema,
  id: z.string(),
  node: z.string(),
  order_id: z.number().positive().int().nullable(),
  status: statusEnumSchema,
})

const workflowStatusSchema = z.object({
  canceled_date: z.coerce.date().nullable().optional(),
  completed_date: z.coerce.date().nullable().optional(),
  description: z.string(),
  estimated_completion: z.string().nullable().optional(),
  id: z.string(),
  input_required: z
    .array(
      z.object({
        attribute: z.string(),
        node_id: z.string(),
      })
    )
    .optional(),
  name: z.string(),
  nodes: z.array(nodeSchema).optional(),
  source_id: z.string().optional(),
  state: stateSchema,
  status: statusEnumSchema.optional(),
  waypoints: z.array(z.unknown()),
})

type WorkflowStatusType = z.infer<typeof workflowStatusSchema>
type NodeType = z.infer<typeof nodeSchema>

const workflowStatusResponseSchema = z.discriminatedUnion('success', [
  z.object({
    message: workflowStatusSchema,
    success: z.literal(true),
  }),
  z.object({
    message: z.string(),
    success: z.literal(false),
  }),
])

export { workflowStatusSchema, workflowStatusResponseSchema, nodeSchema }
export type { WorkflowStatusType, NodeType }
