import type { FieldPropsType } from '@types'
import { loanTypeProps } from '../components/Fields/overrides/loanTypeProps'
import { assertTypeof } from '@utils'

export function parseDateTime(value: string, isDateTime = false) {
  let date: Date | undefined = undefined
  if (typeof value !== 'string') return date
  try {
    date = new Date(value)
  } catch (e) {
    console.error('error creating date', e)
  }
  try {
    console.debug('date', date)
    const parts = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      timeZone: 'UTC',
      month: '2-digit',
      day: '2-digit',
    })
      .formatToParts(date)
      .filter(p => p.type !== 'literal') // remove '/' characters
      .reduce(
        (acc, part) => {
          switch (part.type) {
            case 'day':
              acc.day = part.value
              break
            case 'month':
              acc.month = part.value
              break
            case 'year':
              acc.year = part.value
              break
            default:
              console.error('unexpected date part', part)
              break
          }
          return acc
        },
        { day: '', month: '', year: '' }
      )

    return `${parts.year}-${parts.month}-${parts.day}${isDateTime ? 'T00:00' : ''}`
  } catch (e) {
    console.error('error formatting date', e)
  }
}

// REFACTOR: handle this on the backend...
// Encompass -> Coviance Loan Types
const LOAN_TYPE_MAP: Record<string, string> = {
  'Home Equity Line Of Credit': 'Home Equity',
}

function parseSsn<T>(v: T) {
  if (assertTypeof<string>(v, 'string')) {
    const parsed = v.replace(/[^0-9]/g, '')
    console.info('parsing ssn', v, 'to', parsed)
    return parsed as T
  }
  return v
}

function parseLoanType<T>(v: T) {
  if (typeof v === 'string' && v in LOAN_TYPE_MAP) {
    console.info('loan type', v, 'mapped to', LOAN_TYPE_MAP[v])
    return LOAN_TYPE_MAP[v] as T
  }

  if (
    typeof v === 'string' &&
    !loanTypeProps.options?.map(o => o.name).includes(v)
  ) {
    console.warn(
      'loan type option',
      v,
      'not found in loan type options or in loan type map'
    )
  }
  return v
}

function parseByFieldClass<T>(v: T, field: FieldPropsType) {
  switch (field.field_class) {
    case 'Boolean': {
      if (assertTypeof<boolean>(v, 'boolean')) return v.toString() as T
      return v
    }
    case 'Float': {
      if (assertTypeof<number>(v, 'number')) return v.toFixed(2) as T
      return v
    }
    case 'Integer': {
      if (assertTypeof<number>(v, 'number')) return v.toString() as T
      return v
    }
    case 'Ssn': {
      return parseSsn(v)
    }
    case 'DateTime':
    case 'Date': {
      if (assertTypeof<string>(v, 'string'))
        return parseDateTime(v, field.field_class === 'DateTime') as T
      return v
    }
    default:
      return v
  }
}

function parseByFieldName<T>(v: T, field: FieldPropsType) {
  switch (true) {
    case field.name.includes('ssn'): {
      if (field.field_class !== 'Ssn') {
        console.info("Field name includes 'Ssn' but field class is not 'Ssn'")
        return parseSsn<T>(v)
      }
      return v
    }
    case field.name === 'loan_type': {
      return parseLoanType<T>(v)
    }
    default:
      return v
  }
}

export function processFieldValues<T>(v: T, field?: FieldPropsType) {
  if (field) {
    const processedV = parseByFieldClass<T>(v, field)

    // field name parsing takes precedence
    return parseByFieldName<T>(processedV, field)
  }
  return v
}
