import { z } from 'zod'
import { fieldPropsSchema } from './fieldtypes'

const fieldsSchema = z.object({
  fields: z.array(
    z.intersection(
      z.object({ readonly: z.boolean().optional() }),
      fieldPropsSchema
    )
  ),
})

const fieldsResponseSchema = z.discriminatedUnion('success', [
  z.object({
    message: fieldsSchema,
    success: z.literal(true),
  }),
  z.object({
    message: z.string(),
    success: z.literal(false),
  }),
])

const fieldErrorsSchema = z.record(z.string(), z.array(z.string()))

const fieldErrorsResponseSchema = z.discriminatedUnion('success', [
  z.object({
    message: z.object({
      flow_id: z.string(),
      state: z.record(z.string(), z.unknown()).optional(),
    }),
    success: z.literal(true),
  }),
  z.object({
    message: z.union([z.string(), fieldErrorsSchema]),
    success: z.literal(false),
  }),
])

type FieldsResponseType = z.infer<typeof fieldsResponseSchema>
type FieldsType = z.infer<typeof fieldsSchema>

type FieldErrorsResponseType = z.infer<typeof fieldErrorsResponseSchema>
type FieldErrorsType = z.infer<typeof fieldErrorsSchema>

export { fieldsResponseSchema, fieldErrorsResponseSchema, fieldErrorsSchema }
export type {
  FieldsResponseType,
  FieldsType,
  FieldErrorsResponseType,
  FieldErrorsType,
}
