import { workflowOrderingRoute } from '../../router/authenticated/additionalServicesRoute/workflowRoutes'

import WorkflowForm from './WorkflowForm'

export default function WorkflowOrdering() {
  const formData = workflowOrderingRoute.useLoaderData()
  if (!formData || !formData.success)
    throw new Error(formData?.message || '500')

  const { id, name } = formData.message

  return (
    <div className="relative w-full">
      <h2 className="mb-4 mr-auto">{formData.message.name}</h2>
      <WorkflowForm fieldData={formData.message} flow_id={id} title={name} />
    </div>
  )
}
