import { useFormContext } from 'react-hook-form'

import DropdownField from './Fields/DropdownField'
import Field from './Fields'
import { useRawFields } from '../pages/_helpers'

/**
 * Loan Type Field is used to handle title warranty guardrails
 * in a workflow
 *
 * @todo Verify if we need to create to cover data coming in from Encompass
 * @requires useForm using react-hook-form
 */
export default function LoanTypeField(): JSX.Element {
  const raw = useRawFields()
  const { watch } = useFormContext<{
    loan_type: string | null
    loan_sub_type: string | null
    loan_sub_type_other: string | null
  }>()

  const loanType = watch('loan_type')
  const subType = watch('loan_sub_type')

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="relative w-full">
        <Field
          field={{
            field_class: 'String',
            name: `loan_type`,
            label: 'Loan Type',
            disabled: raw?.find(i => i.name === 'loan_type')?.disabled,
            required: true,
          }}
        />
      </div>
      {loanType === 'Refinance' && (
        <div className="relative w-full">
          <DropdownField
            field={{
              field_class: 'String',
              name: `loan_sub_type`,
              label: 'What type of refinance?',
              required: true,
            }}
            registerOptions={{
              shouldUnregister: true,
            }}
            options={[
              {
                name: 'Home Equity/HELOC Refi',
                label: 'Home Equity/HELOC Refi',
              },
              { name: 'Other Refinance', label: 'Other Refinance' },
            ]}
          />
        </div>
      )}
      {loanType === 'Other' && (
        <div className="relative w-full">
          <DropdownField
            field={{
              field_class: 'String',
              name: `loan_sub_type`,
              required: true,
            }}
            registerOptions={{
              shouldUnregister: true,
            }}
            options={[
              // TODO verify
              { name: 'Reverse Mortgage' },
              { name: 'Recording' },
              { name: 'Personal Loan' },
              { name: 'Portfolio Monitoring' },
              { name: 'Credit Card' },
              { name: 'Auto Loan' },
              { name: 'Other' },
            ]}
          />
        </div>
      )}
      {subType === 'Other' && (
        <div className="relative w-full">
          <Field
            field={{
              field_class: 'String',
              name: `loan_sub_type_other`,
              label: 'What is the other loan?',
              required: true,
            }}
            registerOptions={{
              shouldUnregister: true,
            }}
          />
        </div>
      )}
    </div>
  )
}
