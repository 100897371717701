import { type ChangeEvent, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { CardSection, Field, FieldErrorMessage } from '@components'
import type { FieldComponentPropsType, FieldPropsType } from '@types'
import { getNestedValue } from '@utils'
import { useRawFields } from '../pages/_helpers'

type BorrowersFieldType = {
  borrowers?: string[]
}

/**
 * Borrowers Fields Component is used in Credit Orders
 *
 * @requires useForm using react-hook-form
 */
export default function BorrowersFields({
  size = 'w-full',
}: FieldComponentPropsType) {
  const methods = useFormContext<BorrowersFieldType>()
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = methods
  const rawFields = useRawFields()

  const borrowers = watch('borrowers')

  const selectHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event
    setValue(
      'borrowers',
      target.checked
        ? [...(borrowers || []), target.value]
        : borrowers?.filter(el => el !== target.value) || [],
      { shouldValidate: true }
    )
  }

  // Since conditional fields filter out the fields,
  // we need to manually get them from the rawFields
  const fieldProps = useMemo(() => {
    if (!rawFields) return null

    const next: Record<string, FieldPropsType> = {}

    for (const field of rawFields) {
      if (
        field.name === 'borrower.dob' ||
        field.name === 'borrower.ssn' ||
        field.name === 'coborrower.dob' ||
        field.name === 'coborrower.ssn'
      ) {
        const [start, end] = field.name.split('.')
        next[field.name] = {
          ...field,
          label: `${
            start === 'borrower' ? 'Borrower' : 'Co-Borrower'
          } ${end === 'dob' ? 'Date of Birth' : 'SSN'}`,
          required: true,
        }
      }
    }

    return next as Record<
      'borrower.dob' | 'borrower.ssn' | 'coborrower.dob' | 'coborrower.ssn',
      FieldPropsType
    >
  }, [rawFields])

  if (!fieldProps) {
    console.warn(`No Borrower Fields Found, this shouldn't happen?`)
    return <></>
  }

  return (
    <CardSection title="Borrowers" width={size} required>
      <div className="col-span-2 flex w-full flex-col gap-4">
        {/* Borrower Row */}
        <div className="flex items-start gap-4">
          <label className="flex min-w-32 cursor-pointer flex-row items-center gap-2 text-sm">
            <input
              data-testid="field"
              type="checkbox"
              value="borrower"
              {...register('borrowers', {
                required: true,
                onChange: selectHandler,
              })}
            />
            <p>Borrower</p>
          </label>
          {(borrowers || []).includes('borrower') ? (
            <div className="flex w-full flex-row items-start gap-2">
              <Field field={fieldProps['borrower.dob']} />
              <Field field={fieldProps['borrower.ssn']} />
            </div>
          ) : null}
        </div>
        {/* Co-Borrower Row */}
        <div className="flex items-start gap-4">
          <label className="flex min-w-32 cursor-pointer flex-row items-center gap-2 text-sm">
            <input
              data-testid="field"
              type="checkbox"
              value="coborrower"
              {...register('borrowers', {
                required: true,
                onChange: selectHandler,
              })}
            />
            <p>Co-Borrower</p>
          </label>

          {/* DOB/SSN HERE */}
          {(borrowers || []).includes('coborrower') ? (
            <div className="flex w-full flex-row items-center gap-2">
              <Field field={fieldProps['coborrower.dob']} />
              <Field field={fieldProps['coborrower.ssn']} />
            </div>
          ) : null}
        </div>
        <FieldErrorMessage
          label="At least 1 Borrower or Co-Borrower"
          error={getNestedValue(errors, 'borrowers')}
        />
      </div>
    </CardSection>
  )
}
