import type {
  ConditionalFieldsType,
  FieldPropsType,
  FieldsType,
  WorkflowDetailsType,
} from '@types'
import {
  inspectorContactsConditionalProps,
  borrowersConditionalProps,
  mortgageTypeConditionalProps,
} from '@utils'

/**
 * Base conditional fields are applied to all fields
 */
const BASE_CONDITIONAL_FIELDS: ConditionalFieldsType = {
  ...inspectorContactsConditionalProps,
  ...borrowersConditionalProps,
  ...mortgageTypeConditionalProps,
}

function processConditionalFields(
  fields: FieldPropsType[],
  conditionalFields?: ConditionalFieldsType
) {
  const condFields = { ...BASE_CONDITIONAL_FIELDS, ...conditionalFields }

  let next = [...fields]
  const fieldsToRemove: string[] = []
  const fieldsToInject: FieldPropsType[] = []

  for (const field of fields) {
    // Check if there are any conditional fields
    const conditionalField = condFields?.[field.name]
    if (conditionalField && conditionalField.condition(fields)) {
      // Check if we need to remove any fields
      if (conditionalField.removeFields) {
        fieldsToRemove.push(...conditionalField.removeFields)
      }

      // Check if we need to inject any fields
      if (conditionalField.injectFields) {
        fieldsToInject.push(
          ...conditionalField.injectFields.map(f => {
            return (
              // find the field in the original fields
              // or create a new one using the key
              fields.find(field => field.name === f) ?? {
                name: f,
                required: true,
                field_class: 'unknown' as const,
              }
            )
          })
        )
      }
    }
  }
  if (fieldsToRemove.length) {
    // If we have any fields to remove, filter them out
    next = next.filter(f => !fieldsToRemove.includes(f.name))
  }
  if (fieldsToInject.length) {
    next.push(...fieldsToInject)
  }

  return next
}

const buildProductFields = (data?: FieldsType | null): FieldPropsType[] => {
  if (data == null) return []

  return data.fields.map(({ readonly, ...rest }) => ({
    ...rest,
    disabled: readonly,
  }))
}

const buildWorkflowDetailFields = (data: WorkflowDetailsType | null) => {
  const fields: Array<FieldPropsType> = []
  if (data == null) return fields

  // Add the start attributes & make required
  for (const f of data.start_attributes) {
    fields.push(Object.assign(f, { name: f.attribute, required: true }))
  }

  // Add & transform the fields
  for (const f of [...data.optional_attributes, ...data.required_attributes]) {
    fields.push(Object.assign(f, { disabled: f.readonly }))
  }

  return fields
}

export {
  buildProductFields,
  buildWorkflowDetailFields,
  processConditionalFields,
}
