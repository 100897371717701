import type {
  AdditionalPropsType,
  FieldComponentPropsType,
  FieldPropsType,
} from '@types'

import BureauFields from '../../BureauFields'
import ContactFields from '../../ContactFields'
import LoanTypeField from '../../LoanTypeField'
import BorrowersFields from '../../BorrowersFields'
import DropdownField from '../DropdownField'

// Override Field Properties
import { propertyTypeProps } from './propertyTypeProps'
import { loanTypeProps } from './loanTypeProps'

type FieldFunctionType = (props: FieldComponentPropsType) => JSX.Element
type FullFieldProps = { field?: Partial<FieldPropsType> } & AdditionalPropsType
type FieldOverrideType =
  | [FullFieldProps | undefined, FieldFunctionType]
  | [FullFieldProps, null]

/**
 *
 * Global Field Overrides for all form fields
 *
 * You can provide a custom field component
 *  - if you do, you do NOT need to provide any props
 *  - if you dont, you DO need provide the props
 */
export const override_map: Record<string, FieldOverrideType> = {
  loan_type: [loanTypeProps, DropdownField],
  workflow_loan_type: [undefined, LoanTypeField],
  'subject_property.property_type': [propertyTypeProps, DropdownField],
  lien_position: [
    {
      options: [{ name: '1st Lien' }, { name: 'Subordinate' }],
    },
    DropdownField,
  ],
  inspector_contacts: [undefined, ContactFields],
  borrowers: [undefined, BorrowersFields],
  bureaus: [undefined, BureauFields],
  'service.decision_bundle.product_property_type': [
    {
      options: [
        { name: 'Single Family' },
        { name: 'Condo' },
        { name: 'Townhouse' },
        { name: 'Mobile Home' },
        { name: 'PUD' },
        { name: 'Duplex' },
        { name: 'Triplex' },
        { name: 'Quadraplex' },
      ],
    },
    DropdownField,
  ],
}
