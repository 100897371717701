import {
  ErrorComponent,
  NotFoundRoute,
  createRoute,
  createRouter,
} from '@tanstack/react-router'
import { authenticatedRoute, orderStatusRoute, devRoute } from './authenticated'
import {
  noWorkflowsRoute,
  workflowsRoute,
  workflowsRedirectRoute,
  workflowsIndexRoute,
  workflowOrderingRoute,
  workflowStatusRoute,
} from './authenticated/additionalServicesRoute/workflowRoutes'
import {
  appraisalRoute,
  floodRoute,
  titleRoute,
} from './authenticated/productRoutes'
import {
  additionalServicesRoute,
  landingRoute,
  creditRoute,
  submitRoute,
} from './authenticated/additionalServicesRoute'
import { rootRoute } from './rootRoute'

const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/',
  loader: async ({ navigate }) => {
    await navigate({ to: '/dev' })
    return null
  },
  errorComponent: ErrorComponent,
})

const notFoundRoute = new NotFoundRoute({
  getParentRoute: () => rootRoute,
  component: () => '404 Not Found',
})

const routeTree = rootRoute.addChildren([
  indexRoute,
  authenticatedRoute.addChildren([
    devRoute,
    // Additional Services
    additionalServicesRoute.addChildren([
      landingRoute,
      creditRoute,
      workflowsRoute.addChildren([
        workflowsRedirectRoute,
        workflowsIndexRoute,
        workflowOrderingRoute,
        workflowStatusRoute,
        noWorkflowsRoute,
      ]),
    ]),
    appraisalRoute,
    floodRoute,
    titleRoute,

    orderStatusRoute,
    submitRoute,
  ]),
])

const router = createRouter({
  routeTree,
  notFoundRoute,
  defaultPendingMs: 200,
  // defaultPendingMinMs: 200,
  // defaultPreloadDelay: 0,
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

export { router }
export { RouterDevTools } from './RouterDevTools'
