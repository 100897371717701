import { parseDateTime } from '@utils'
import { type RefObject, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

/**
 * Form Field Helper for handling different data-attributes
 *
 * @param ref the field ref
 * @param field the name of the field
 *
 * @requires useForm use inside of a react-hook-form
 */
export function useDataAttributes<T extends RefObject<HTMLElement>>(
  ref: T,
  field: string
) {
  const { setFocus, watch } = useFormContext()
  const value = watch(field) as unknown
  useEffect(() => {
    // On mount set the data-empty attribute
    ref.current?.setAttribute('data-empty', value ? 'false' : 'true')
  }, [field, ref, value])

  return {
    onFocus: () => {
      ref.current?.setAttribute('data-active', 'true')
    },
    onLabelClick: () => {
      ref.current?.setAttribute('data-active', 'true')
      setFocus(field)
    },
  }
}

/**
 * Form Field Helper for handling pasting dates
 *
 * @param name The field name
 * @param ref The container ref for the field
 * @param dateTime Whether or not the field is a date-time field
 */
export function useDatePaste(
  name: string,
  ref: React.RefObject<HTMLDivElement>,
  dateTime = false
) {
  const { setValue, setError } = useFormContext()
  useEffect(() => {
    const inputEl = ref?.current?.querySelector('input')

    const onPaste = (e: ClipboardEvent) => {
      e.preventDefault()
      let value: string | undefined = undefined
      if (e.clipboardData) {
        const text = e.clipboardData.getData('text/plain')

        value = parseDateTime(text, dateTime)
      }
      if (!value) {
        setValue(name, '')
        setError(name, {
          type: 'invalid-date-format',
          message: 'Invalid date format. Please use MM/DD/YYYY format.',
        })
      } else {
        setValue(name, value, {
          shouldValidate: true,
        })
      }
    }
    if (inputEl) {
      inputEl.addEventListener('paste', onPaste)
    }
    return () => {
      if (inputEl) {
        inputEl.removeEventListener('paste', onPaste)
      }
    }
  }, [name, setValue, setError, ref, dateTime])
}

export const DISABLED_PROPS = {
  'aria-disabled': true,
  disabled: true,
  title: 'To change this value, please return to your Encompass Loan to edit.',
} as const
