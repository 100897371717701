import { z } from 'zod'

export const productSchema = z.object({
  category: z.string().nullable(),
  available: z.boolean().optional(),
  sub_product_description: z.string(),
  sub_product_name: z.string(),
  vendor_description: z.string(),
  vendor_name: z.string(),
  vendor_product_id: z.number(),
  request_type: z.string(),
})

export type ProductType = z.infer<typeof productSchema>

export const productResponseSchema = z.discriminatedUnion('success', [
  z.object({
    message: z.array(productSchema),
    success: z.literal(true),
  }),
  z.object({
    message: z.string(),
    success: z.literal(false),
  }),
])

export type ProductResponseType = z.infer<typeof productResponseSchema>
