import { authFetch } from '@services/authFetch'
import { workflowOverrideNodeResponseSchema } from '@types'
import { objectToDot } from '@utils'

/**
 * Post Callback is the api call used for override/decision nodes
 */
export const postOverrideNode = async (formData: Record<string, unknown>) => {
  console.log('Posting Workflow Override Node Data')
  const res = await authFetch('/api/encompass/lia/callback', {
    method: 'POST',
    body: JSON.stringify(formData),
  })

  if (res.ok) {
    const data = (await res.json()) as { message: unknown; success: boolean }
    const transformed = objectToDot(data.message)

    // if the transform fails fallback to the original data
    data['message'] = transformed ?? data.message

    const parsed = workflowOverrideNodeResponseSchema.parse(data)
    return parsed
  } else if (res.status === 500) {
    return res
  }
}
