import { z } from 'zod'
import { statusEnumSchema } from './covianceStatuses'

export const orderStatusSchema = z.object({
  order: z.object({
    date_completed: z.string().nullable(),
    duration: z.string().optional(),
    date_started: z.string(),
    id: z.number(),
    message: z.string(),
    // TODO: do these statuses match transactionStatusEnumSchema statuses?
    status: statusEnumSchema,
  }),
  product: z.object({
    id: z.number(),
    name: z.string(),
    vendor: z.string(),
  }),
  subject_property: z.object({
    city: z.string(),
    postal_code: z.string(),
    postal_county: z.string(),
    postal_parcel_id: z.string().optional().nullable(),
    state: z.string(),
    street: z.string(),
  }),
})

export type OrderStatusType = z.infer<typeof orderStatusSchema>
