import { createRoute } from '@tanstack/react-router'

import { authenticatedRoute } from '..'

const appraisalRoute = createRoute({
  getParentRoute: () => authenticatedRoute,
  path: 'products/appraisal',
}).lazy(() => import('../../../pages/AppraisalOrder').then(m => m.Route))

const floodRoute = createRoute({
  getParentRoute: () => authenticatedRoute,
  path: 'products/flood',
}).lazy(() => import('../../../pages/FloodOrder').then(m => m.Route))

const titleRoute = createRoute({
  getParentRoute: () => authenticatedRoute,
  path: 'products/title',
}).lazy(() => import('../../../pages/TitleOrder').then(m => m.Route))

export { appraisalRoute, floodRoute, titleRoute }
