import { createContext } from 'react'
import { hostConnection } from '@services/hostConnection'
import { getOrigin } from '@services/getOrigin'

/**
 * Replaces all non-alphanumeric characters with a space
 */
export function prettify(str: string): string {
  return str.replace(/[^a-zA-Z0-9]+/g, ' ')
}

type TypeofEnum =
  | 'string'
  | 'number'
  | 'bigint'
  | 'boolean'
  | 'symbol'
  | 'undefined'
  | 'object'
  | 'function'

/**
 * Utility function for type checking in condtionals
 * @param v The value to check
 * @param t The type to check against
 */
export function assertTypeof<T>(v: unknown, t?: TypeofEnum): v is T {
  if (t && typeof v !== t) {
    console.debug('value', v, 'is not of type', t)
    return false
  }
  return true
}

export type HostContextType = Awaited<ReturnType<typeof hostConnection>>
export const HostContext = createContext<HostContextType>(null!)

export type OriginContextType =
  | (Awaited<ReturnType<typeof getOrigin>> & { transactionId?: string })
  | undefined
export const OriginContext = createContext<OriginContextType>(undefined)

export { convertNestedObjectToDotNotation as objectToDot } from './objectToDot'
export { getNestedValue } from './getNestedValue'
export { fieldServerErrors } from './fieldServerErrors'

export { TimeoutError, rejectAfterTimeout } from './timeout'

export {
  borrowersConditionalProps,
  inspectorContactsConditionalProps,
  mortgageTypeConditionalProps,
} from './conditionalFields'

export { parseDateTime, processFieldValues } from './parsingHelpers'
export { useDebounce } from './useDebounce'
