import imgUrl from '@assets/spinner.svg'

/**
 * Pending should be wrapped in a `position:relative;` element.
 */
export default function Pending({
  position = 'fixed',
  opacity = 30,
}: {
  position?: 'fixed' | 'absolute'
  opacity?: 5 | 10 | 20 | 30 | 50 | 70
}) {
  return (
    <span
      className={`${position} inset-0 z-10 grid select-none place-content-center bg-white bg-opacity-${opacity}`}
    >
      <img src={imgUrl} data-testid="spinner" className="animate-spin p-8" />
    </span>
  )
}
