import { useFormContext } from 'react-hook-form'
import { type ChangeEvent, useState } from 'react'
import { CardSection, FieldErrorMessage } from '@components'
import { getNestedValue } from '@utils'

type BureausType = 'all' | 'equifax' | 'experian' | 'transunion'
const ALL_BUREAUS: BureausType[] = ['all', 'equifax', 'experian', 'transunion']

/**
 * Credit Bureau Field is used in Credit Orders
 *
 * @todo Verify if we need to create to cover data coming in from Encompass
 * @requires useForm using react-hook-form
 */
export default function BureauFields() {
  const {
    register,
    formState: { errors },
  } = useFormContext<{ bureaus?: string }>()

  const [bureaus, setBureaus] = useState<BureausType[]>([])

  const creditHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setBureaus(prev => {
      const { target } = event
      const v = target.value as (typeof prev)[0]

      if (v === 'all') {
        return target.checked ? ALL_BUREAUS : []

        // Remove bureau or all
      } else if (prev.includes(v)) {
        return [...prev.filter(el => el !== v && el !== 'all')]

        // if all three bureaus are selected but not all make sure to select all as well
      } else if (prev.length === 2 && !prev.includes('all')) {
        return ALL_BUREAUS

        // Else just add the bureau
      } else {
        return [...prev, v]
      }
    })
  }

  return (
    <CardSection title="Bureaus" width="w-full" required>
      <fieldset data-testid="field" className="flex flex-col items-start gap-2">
        <label className="flex cursor-pointer flex-row items-center gap-2">
          <input
            className="peer"
            value="all"
            type="checkbox"
            {...register('bureaus', {
              onChange: creditHandler,
              required: true,
            })}
            checked={bureaus.includes('all')}
          />
          <p className="text-sm text-gray-500 peer-checked:text-black">
            Select All
          </p>
        </label>
        <label className="flex cursor-pointer flex-row items-center gap-2">
          <input
            type="checkbox"
            value="equifax"
            {...register('bureaus', {
              onChange: creditHandler,
              required: true,
            })}
            checked={bureaus.includes('equifax')}
          />
          <p className="text-sm">Equifax</p>
        </label>
        <label className="flex cursor-pointer flex-row items-center gap-2">
          <input
            type="checkbox"
            value="experian"
            {...register('bureaus', {
              onChange: creditHandler,
              required: true,
            })}
            checked={bureaus.includes('experian')}
          />
          <p className="text-sm">Experian</p>
        </label>
        <label className="flex cursor-pointer flex-row items-center gap-2">
          <input
            type="checkbox"
            value="transunion"
            {...register('bureaus', {
              onChange: creditHandler,
              required: true,
            })}
            checked={bureaus.includes('transunion')}
          />
          <p className="text-sm">Transunion</p>
        </label>
        <FieldErrorMessage
          label="Bureaus"
          error={getNestedValue(errors, 'bureaus')}
        />
      </fieldset>
    </CardSection>
  )
}
