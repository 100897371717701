import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from '@tanstack/react-router'

import { router } from './router'
import './global.css'

//#region Startup Code
async function enableMocking() {
  if (import.meta.env.DEV) {
    const { worker } = await import('./services/mocks/browser')
    return worker.start({
      onUnhandledRequest: req =>
        console.debug(`No handler for ${req.url}`, req),
      quiet: true,
    })
  }
}

function initLogger() {
  const NO_OP = () => null
  const LEVEL = import.meta.env.VITE_LOG_LEVEL
  console.log = LEVEL === '0' ? console.log : NO_OP
  console.info = LEVEL !== '2' ? console.info : NO_OP
  console.debug = LEVEL === '0' ? console.debug : NO_OP
  console.group = LEVEL === '0' ? console.group : NO_OP
  console.groupEnd = LEVEL === '0' ? console.groupEnd : NO_OP
}
initLogger()
//#endregion

void enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  )
})
