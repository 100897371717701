import { createRoute } from '@tanstack/react-router'
import { authenticatedRoute } from '..'
import { Outlet } from '@tanstack/react-router'
import { submitSearchSchema } from '@types'

const additionalServicesRoute = createRoute({
  getParentRoute: () => authenticatedRoute,
  path: 'additional-services',
  component: Outlet,
})

const landingRoute = createRoute({
  getParentRoute: () => additionalServicesRoute,
  path: '/',
}).lazy(() => import('../../../pages/Landing').then(m => m.Route))

const creditRoute = createRoute({
  getParentRoute: () => additionalServicesRoute,
  path: 'credit',
}).lazy(() => import('../../../pages/CreditOrder').then(m => m.Route))

const submitRoute = createRoute({
  getParentRoute: () => authenticatedRoute,
  path: 'submit',
  validateSearch: submitSearchSchema,
}).lazy(() => import('../../../pages/Submit').then(m => m.Route))

export { creditRoute, additionalServicesRoute, landingRoute, submitRoute }
