import { z } from 'zod'

const statusEnumSchema = z.enum([
  // Coviance Specific
  'waiting',
  'working',
  'complete',
  'error',
  'skipped',
  'canceled',
  'stopped',
  'submit',
  'no_hit',
  'in_research',
  'need_info',
  'need input',
  'error_skipped',
  'prepared',
  'connection_error',

  // Encompass Specific
  'processing',
  'Complete',
  'File Validation Error',
  'Inspection Scheduled',
])
type StatusEnumType = z.infer<typeof statusEnumSchema>

export { statusEnumSchema }
export type { StatusEnumType }
