import { useState } from 'react'
import Button from './Button'
import { Icon } from './Icon'

function VisibilityButton({
  onClick,
}: {
  onClick: (isVisible: boolean) => void
}) {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <div className="absolute right-2 top-0 flex h-full items-center">
      <Button
        size="none"
        variant="text"
        className="flex h-8 w-8 items-center justify-center rounded-full"
        onClick={e => {
          e.preventDefault()
          setIsVisible(prev => {
            const next = !prev
            onClick(next)
            return next
          })
        }}
      >
        <Icon
          type={isVisible ? 'visibilityOff' : 'visibility'}
          className="h-5 w-5 p-0 text-gray-500"
          data-testid={isVisible ? 'visibility-off' : 'visibility-on'}
        />
      </Button>
    </div>
  )
}

export default VisibilityButton
