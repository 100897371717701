import { Field } from '@components'
import type { FieldPropsType } from '@types'
import { useMemo } from 'react'
import type { SectionType } from './_helpers'

type SectionFieldsProps = {
  /**
   * The section Fields
   */
  fields: SectionType['fields']
  /**
   * The field data
   */
  data: FieldPropsType[]
  /**
   * The Section width
   */
  width?: 'w-1/2' | 'w-full'
}
export function SectionFields({
  fields,
  data,
  width = 'w-1/2',
}: SectionFieldsProps) {
  // generate form fields based on fields & data
  const fieldData = useMemo(
    () =>
      fields
        .map(f => data.find(d => d.name === f) || false)
        .filter(Boolean) as FieldPropsType[],
    [data, fields]
  )
  return (
    <>
      <div className={`items inline-flex ${width} flex-col gap-4`}>
        {fieldData.map(f => {
          return <Field field={f} key={f.name} />
        })}
      </div>
    </>
  )
}
