import { RawFieldsContext } from './Context'

function RawFieldsProvider({
  children,
  data,
}: {
  children: React.ReactNode
  data: typeof RawFieldsContext extends React.Context<infer T> ? T : never
}) {
  return (
    <RawFieldsContext.Provider value={data}>
      {children}
    </RawFieldsContext.Provider>
  )
}

export { RawFieldsProvider }
