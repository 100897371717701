import { rejectAfterTimeout, TimeoutError, type HostContextType } from '@utils'
import { hostConnection } from '@services/hostConnection'
import { DEV_HOST } from './DEV_HOST'

export class AuthenticationError extends Error {}

// Dependancy Injection for local dev bypass
const host = import.meta.env.DEV
  ? DEV_HOST
  : import('@elliemae/em-ssf-guest').then(res => res.default)

export async function hostLoader(): Promise<HostContextType> {
  const resolved_host = await host
  try {
    const _hostConnection = await Promise.race([
      hostConnection(resolved_host),
      rejectAfterTimeout(),
    ])
    if (_hostConnection) {
      return _hostConnection
    }
  } catch (error) {
    if (!(error instanceof TimeoutError)) {
      throw error
    }
  }
  throw new AuthenticationError('Cannot authenticate with Encompass')
}
