import { Fragment, useRef } from 'react'
import {
  Controller,
  type FieldError,
  type FieldValues,
  type UseFormSetValue,
  useFormContext,
  // type RegisterOptions,
} from 'react-hook-form'

import { getNestedValue, prettify } from '@utils'
import type { FieldComponentPropsType, FieldOptionsType } from '@types'
import downArrow from '@assets/down-arrow-circle.svg'

import FieldErrorMessage from './FieldErrorMessage'
import FieldLabel from './FieldLabel'
import { Listbox, Transition } from '@headlessui/react'
import { DISABLED_PROPS, useDataAttributes } from './_helpers'

/**
 * Shared Dropdown Field Component
 *
 * @requires useForm using react-hook-form
 */
export default function DropdownField({
  field,
  options,
  registerOptions,
}: FieldComponentPropsType) {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext()
  const containerRef = useRef<HTMLDivElement>(null)
  const { onFocus, onLabelClick } = useDataAttributes(containerRef, field.name)
  const value = watch<string>(field.name) as string | undefined
  const fieldLabel = field.label || prettify(field.name)
  const error = getNestedValue<FieldError>(errors, field.name)

  const valueLabel = options?.find(o => o.name === value)?.label

  return (
    <div
      ref={containerRef}
      data-testid="field"
      data-invalid={error ? 'true' : 'false'}
      className="group w-full"
    >
      <Controller
        name={field.name}
        control={control}
        shouldUnregister={registerOptions?.shouldUnregister}
        rules={{ required: field.required, ...registerOptions }}
        render={() => (
          <>
            <Listbox disabled={field.disabled}>
              <div className="relative">
                <FieldLabel
                  label={fieldLabel}
                  required={field.required}
                  onClick={onLabelClick}
                />
                <Listbox.Button
                  {...(field.disabled === true ? DISABLED_PROPS : null)}
                  aria-invalid={error ? 'true' : 'false'}
                  className="group min-h-[3rem] w-full rounded-md border border-gray-400 p-3 text-sm disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-50 disabled:bg-opacity-50 disabled:text-gray-500 group-data-[invalid=true]:border-red-500 group-data-[invalid=true]:text-red-500"
                >
                  <span className="block truncate text-left capitalize">
                    {valueLabel || value}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 transition-transform group-disabled:opacity-50 ui-open:rotate-180">
                    <img src={downArrow} />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-75"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    onFocus={onFocus}
                  >
                    {field.required ? null : (
                      // If this is an optional field, add a 'blank' option to clear the value
                      <DropdownOption
                        field={field}
                        fieldRef={containerRef}
                        option={{ name: '' }}
                      />
                    )}
                    {options?.map(o => (
                      <DropdownOption
                        key={o.name}
                        field={field}
                        fieldRef={containerRef}
                        option={o}
                        onChange={registerOptions?.onChange}
                      />
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
            <FieldErrorMessage label={fieldLabel} error={error} />
          </>
        )}
      />
    </div>
  )
}

function DropdownOption({
  option,
  fieldRef,
  field,
  onChange,
}: {
  option: FieldOptionsType
  fieldRef: React.RefObject<HTMLDivElement>
  field: FieldComponentPropsType['field']
  onChange?: UseFormSetValue<FieldValues>
}) {
  const { clearErrors, setValue } = useFormContext()
  const handler = onChange || setValue
  return (
    <Listbox.Option
      key={option.name}
      {...(field?.disabled === true ? DISABLED_PROPS : null)}
      onClick={() => {
        if (option.name) {
          fieldRef.current?.setAttribute('data-empty', 'false')
          clearErrors(field.name)
        } else {
          fieldRef.current?.setAttribute('data-empty', 'true')
        }
        handler(field.name, option.name)
      }}
      className="relative min-h-[2rem] cursor-pointer select-none py-2 pl-10 pr-4 ui-active:bg-primary-light ui-active:text-primary ui-not-active:text-gray-900"
      value={option.name}
    >
      <>
        <span className="block truncate ui-selected:font-medium ui-not-selected:font-normal">
          {option.label || option.name}
        </span>
        <span
          className={`absolute inset-y-0 left-0 hidden items-center pl-3 text-primary ${
            option.name ? 'ui-selected:flex' : ''
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
            className="h-5 w-5"
          >
            <path
              fillRule="evenodd"
              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
              clipRule="evenodd"
            ></path>
          </svg>
        </span>
      </>
    </Listbox.Option>
  )
}
