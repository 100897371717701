import { CloseButton } from '@components'

import imgUrl from '@assets/empty-tray.svg'

export default function NoWorkflows() {
  return (
    <div className="flex flex-col items-center gap-8">
      <img src={imgUrl} />
      <span className="text-center">
        Check back soon!
        <p>Your custom Automation workflows are being created.</p>
      </span>
      <CloseButton variant="primary" size="md" />
    </div>
  )
}
