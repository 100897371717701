import { useEffect, useRef, type ChangeEvent } from 'react'
import { useFormContext, type FieldError } from 'react-hook-form'

import { getNestedValue, prettify } from '@utils'
import type { FieldComponentPropsType } from '@types'

import { DISABLED_PROPS, useDataAttributes, useDatePaste } from './_helpers'
import FieldErrorMessage from './FieldErrorMessage'
import FieldLabel from './FieldLabel'
import VisibilityButton from '../VisibilityButton'

/**
 * Shared DateTime Field Component
 *
 * @requires useForm using react-hook-form
 */
export default function DateTimeField({
  field,
  usePii = false,
  registerOptions,
}: FieldComponentPropsType) {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext()
  const containerRef = useRef<HTMLDivElement>(null)
  const getInputEl = () => containerRef.current?.querySelector('input')
  useDatePaste(field.name, containerRef, true)
  const { onFocus, onLabelClick } = useDataAttributes(containerRef, field.name)

  const fieldLabel = field.label || prettify(field.name)
  const error = getNestedValue<FieldError>(errors, field.name)
  const value = watch(field.name) as string

  const showVisibilityButton =
    usePii && field.disabled === true && Boolean(value)

  useEffect(() => {
    // set input type to password
    const inputEl = getInputEl()
    if (usePii && inputEl && inputEl.value !== '') {
      inputEl.type = 'password'
    }
  }, [usePii])

  return (
    <div
      ref={containerRef}
      data-testid="field"
      data-invalid={error ? 'true' : 'false'}
      className="group w-full"
    >
      <div className="relative">
        <FieldLabel
          label={fieldLabel}
          required={field.required}
          onClick={onLabelClick}
        />
        <input
          type="datetime-local"
          {...register(field.name, {
            required: field.required,
            ...registerOptions,
            onBlur: () => {
              const inputEl = getInputEl()
              if (inputEl && usePii && inputEl.value !== '') {
                inputEl.type = 'password'
              }
            },
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              setValue(field.name, e.target.value, { shouldValidate: true })
            },
          })}
          onFocus={() => {
            const inputEl = getInputEl()
            if (inputEl) {
              inputEl.style.color = 'inherit'
              if (usePii) {
                inputEl.type = 'datetime-local'
              }
            }
            onFocus()
          }}
          autoComplete="off"
          aria-invalid={error ? 'true' : 'false'}
          {...(field.disabled === true ? DISABLED_PROPS : null)}
          className="w-full rounded-md border border-gray-400 p-3 text-sm disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-50 disabled:bg-opacity-50 disabled:text-gray-500 group-data-[invalid=true]:border-red-500 group-data-[invalid=true]:text-red-500"
        />
        {showVisibilityButton && (
          <VisibilityButton
            onClick={isVisible => {
              const inputEl = getInputEl()
              if (inputEl) {
                inputEl.type = isVisible ? 'datetime-local' : 'password'
              }
            }}
          />
        )}
      </div>
      <FieldErrorMessage label={fieldLabel} error={error} />
    </div>
  )
}
