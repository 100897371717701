import { authFetch } from '@services/authFetch'
import { workflowInteractivePostSchema } from '@types'
import { objectToDot } from '@utils'

export const postInteractiveNode = async (
  running_flow_id: string,
  formData: Record<string, unknown>,
  node_id: string
) => {
  console.log('Posting Interactive Node Data')
  const res = await authFetch(
    `/api/encompass/lia/interactive/${running_flow_id}/info`,
    {
      method: 'POST',
      body: JSON.stringify({
        node_id,
        response: formData,
      }),
    }
  )
  if (res.ok) {
    const data = (await res.json()) as { message: unknown; success: boolean }
    if (typeof data.message !== 'string') {
      const transformed = objectToDot(data.message)
      data['message'] = transformed ?? data.message
    }
    const parsed = workflowInteractivePostSchema.parse(data)

    return parsed
  } else {
    return res
  }
}
