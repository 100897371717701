import type { HTMLAttributes } from 'react'
import type { FieldError } from 'react-hook-form'

/**
 * Default classes
 * 'mt-1 px-2 text-[10px] capitalize text-red-500'
 */
export default function FieldErrorMessage({
  label,
  error,
  className = 'mt-1 px-2 text-[10px] capitalize text-red-500',
}: {
  label: string
  error?: FieldError
  className?: HTMLAttributes<HTMLParagraphElement>['className']
}) {
  if (!error) return null
  return (
    <p role="alert" className={className}>
      {error?.type === 'required' ? `${label} is required` : error?.message}
    </p>
  )
}
