import { z } from 'zod'
import { fieldClassEnumSchema } from './fieldtypes'
import { fieldErrorsSchema } from './fields'

const displayContentSchema = z
  .object({
    content: z.record(z.string(), z.unknown()),
    subHeading: z.string().nullable(),
    title: z.string().nullable(),
  })
  .nullable()

const optionsSchema = z.object({
  icon: z.string().optional(),
  label: z.string(),
  value: z.string(),
})

const interactiveAttributeSchema = z.object({
  field_class: fieldClassEnumSchema,
  label: z.string().nullable(),
  name: z.string(),
  options: z.array(optionsSchema).nullable(),
  required: z.boolean(),
  type: z.string().optional(),
})
type InteractiveAttributeType = z.infer<typeof interactiveAttributeSchema>

const workflowInteractiveSchema = z.object({
  attributes: z.array(interactiveAttributeSchema),
  content: displayContentSchema,
  display_info: displayContentSchema,
  future_nodes: z.array(z.string()),
  node_id: z.string(),
  version: z.literal('0.1.0'),
})

const workflowInteractiveResponseSchema = z.object({
  message: workflowInteractiveSchema.optional(),
  success: z.boolean(),
})

const workflowInteractivePostSchema = z.discriminatedUnion('success', [
  z.object({
    message: z.string(),
    success: z.literal(true),
  }),
  z.object({
    message: z.union([z.string(), fieldErrorsSchema]),
    success: z.literal(false),
  }),
])

type WorkflowInteractiveType = z.infer<typeof workflowInteractiveSchema>

export {
  workflowInteractiveSchema,
  workflowInteractiveResponseSchema,
  workflowInteractivePostSchema,
}
export type { WorkflowInteractiveType, InteractiveAttributeType }
