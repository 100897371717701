import { type FieldError, useFormContext } from 'react-hook-form'

import { getNestedValue, prettify } from '@utils'
import type { FieldComponentPropsType } from '@types'

import FieldErrorMessage from './FieldErrorMessage'
import FieldLabel from './FieldLabel'
import { DISABLED_PROPS, useDataAttributes } from './_helpers'
import { useMemo, type FormEvent, type HTMLAttributes, useRef } from 'react'

/**
 * Shared Boolean Field Component
 *
 * @requires useForm using react-hook-form
 */
export default function BooleanField({ field }: FieldComponentPropsType) {
  const {
    setValue,
    formState: { errors },
    setError,
    clearErrors,
    watch,
  } = useFormContext()
  const fieldValue = watch(field.name) as string | undefined
  const containerRef = useRef<HTMLDivElement>(null)
  const { onFocus, onLabelClick } = useDataAttributes(containerRef, field.name)

  const fieldLabel = field.label || prettify(field.name)
  const error = getNestedValue<FieldError>(errors, field.name)

  const sharedProps = useMemo(() => {
    return {
      name: field.name,
      required: field.required,
      disabled: field.disabled,
      onFocus,
      onInvalid: (e: FormEvent) => {
        e.preventDefault()
        e.stopPropagation()
        setError(
          field.name,
          {
            type: 'required',
          },
          { shouldFocus: true }
        )
      },
    } as HTMLAttributes<HTMLInputElement>
  }, [field.name, field.required, field.disabled, setError, onFocus])

  return (
    <div
      ref={containerRef}
      data-testid="field"
      data-invalid={error ? 'true' : 'false'}
      className="group relative flex w-full flex-wrap gap-4 p-2 text-sm"
    >
      <FieldLabel
        label={fieldLabel}
        required={field.required}
        variant="static"
      />
      <span className="mr-auto"></span>
      <InputLabel
        htmlFor={`${field.name}_radio_true`}
        onClick={onLabelClick}
        disabled={field.disabled}
      >
        True
      </InputLabel>
      <FieldInput
        {...sharedProps}
        id={`${field.name}_radio_true`}
        checked={fieldValue === 'true'}
        onChange={() => {
          setValue(field.name, 'true')
          clearErrors(field.name)
        }}
      />
      <InputLabel
        htmlFor={`${field.name}_radio_false`}
        onClick={onLabelClick}
        className="ml-8"
        disabled={field.disabled}
      >
        False
      </InputLabel>
      <FieldInput
        {...sharedProps}
        id={`${field.name}_radio_false`}
        checked={fieldValue === 'false'}
        onChange={() => {
          setValue(field.name, 'false')
          clearErrors(field.name)
        }}
      />
      <FieldErrorMessage
        label={fieldLabel}
        error={error}
        className="mt-1 w-full text-[10px] capitalize text-red-500"
      />
    </div>
  )
}

function InputLabel(
  props: React.ComponentPropsWithRef<'label'> & { disabled?: boolean }
) {
  return (
    <label
      {...props}
      className={`group-data-[invalid=true]:text-red-500 ${
        props.disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
      } ${props.className ? props.className : ''}`}
    >
      {props.children}
    </label>
  )
}

function FieldInput(props: React.ComponentPropsWithRef<'input'>) {
  return (
    <input
      {...props}
      type="radio"
      autoComplete="off"
      {...(props.disabled === true ? DISABLED_PROPS : null)}
      className={`aspect-square h-5 w-5 ${props.disabled === true ? 'cursor-not-allowed' : 'cursor-pointer'} rounded-full border-2 border-current text-primary hover:scale-105 active:scale-95 disabled:opacity-50 disabled:checked:bg-primary group-data-[invalid=true]:text-red-500 checked:group-data-[invalid=true]:bg-red-500`}
    />
  )
}
