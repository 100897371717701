import type { FieldError } from 'react-hook-form'

export type FormErrors = {
  [key: string]: FieldError | Array<FieldError> | Record<string, FieldError>
}

export function handleFormErrors(
  formErrors: unknown,
  path = '',
  results: { [key: string]: FieldError }[] = []
) {
  if (!formErrors || typeof formErrors === 'string') return results
  const errors = formErrors as FormErrors
  for (const key in errors) {
    if (!errors[key]) continue
    const p = path ? `${path}.${key}` : key

    const err = errors[key]
    if (Array.isArray(err)) {
      for (const e of err) {
        results.push({ [p]: { ...e, message: e?.message || e.type } })
      }
      continue
    }

    if (err && 'type' in err) {
      const e = err as FieldError
      results.push({
        [p]: {
          ...e,
          message: e.message || e.type,
        },
      })
    } else {
      // Recur
      handleFormErrors(err as FormErrors, p, results)
    }
  }

  return results
}
