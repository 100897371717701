import type { FieldPropsType } from '@types'
import type { SectionType } from './_helpers'
import { SectionFields } from './SectionFields'
import { Card } from '@components'

export function Sections({
  sections,
  data,
}: {
  sections: SectionType[]
  data: (FieldPropsType & { value?: unknown })[]
}) {
  return sections.map(s => {
    if (s.title === '__no_section__') {
      return (
        <div className="w-full py-4 pl-8" key={s.title}>
          <SectionFields fields={s.fields} data={data} width="w-full" />
        </div>
      )
    }
    return (
      <Card title={s.title} key={s.title}>
        <div className="flex flex-row items-stretch justify-stretch gap-4">
          {/* Section Content is actually rendering the fields  */}
          <SectionFields fields={s.fields} data={data} />
        </div>
      </Card>
    )
  })
}
