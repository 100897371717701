import { memo } from 'react'

import type { FieldComponentPropsType, FieldPropsType } from '@types'

import FloatField from './FloatField'
import IntegerField from './IntegerField'
import DateField from './DateField'
import StringField from './StringField'
import PhoneField from './PhoneField'
import EmailField from './EmailField'
import DateTimeField from './DateTimeField'
import BooleanField from './BooleanField'
import SsnField from './SsnField'

import { override_map } from './overrides'
import type { RegisterOptions } from 'react-hook-form'

type UsedFieldClasses = Exclude<
  FieldPropsType['field_class'],
  // currently unsupported field_classes
  'Document' | 'File'
>

const field_class_map: Record<UsedFieldClasses, FieldComponentType> = {
  Boolean: BooleanField,
  Date: DateField,
  DateTime: DateTimeField,
  Email: EmailField,
  Float: FloatField,
  Integer: IntegerField,
  Phone: PhoneField,
  String: StringField,
  Ssn: SsnField,
  // Unknown fields Fallback to Strings
  unknown: StringField,
}

type FieldComponentType = (props: FieldComponentPropsType) => JSX.Element

/**
 * ### Dynamic Form Fields
 *
 * This component determines which Field component to render
 *
 * `If we want to get more dynamic than this we need to expand our
 * field schema's & api's`
 *
 * @requires useForm from 'react-hook-form'
 */
const Field = memo(function MemoField({
  field,
  registerOptions,
}: FieldComponentPropsType) {
  // Hide Unsupported Fields
  if (field.field_class === 'Document' || field.field_class === 'File')
    return null

  // Get the Field Component
  let Comp = field_class_map[field.field_class]

  // Check for overrides
  if (field.name in override_map) {
    const [overrideProps, comp] = override_map[field.name]!
    // if the override has a component, use it
    if (comp) Comp = comp

    return (
      <Comp
        {...overrideProps}
        field={{ ...field, ...overrideProps?.field }}
        registerOptions={
          {
            ...registerOptions,
            ...overrideProps?.registerOptions,
          } as RegisterOptions
        }
      />
    )
  }

  // Check for multi-field overrides
  const extraProps = getMultiFieldOverride(field)
  if (extraProps) {
    const [overrideProps, comp] = extraProps
    // if the override has a component, use it
    if (comp) Comp = comp
    return (
      <Comp
        {...overrideProps}
        registerOptions={
          {
            ...registerOptions,
            ...overrideProps?.registerOptions,
          } as RegisterOptions
        }
      />
    )
  }

  // Default
  return <Comp field={field} registerOptions={registerOptions} />
})

/**
 * Helper function to inject props to multiple fields
 */
function getMultiFieldOverride(
  field: FieldPropsType
): [FieldComponentPropsType, FieldComponentType | null] | null {
  if (field.name.includes('dob')) {
    return [
      {
        field,
        usePii: true,
      },
      null,
    ]
  }

  // No props to inject
  return null
}

export default Field
