import { authFetch } from '@services/authFetch'
import { workflowDetailsResponseSchema } from '@types'

const fetchWorkflow = async (workflow_id: string) => {
  console.log('Fetching Workflow Details')
  const res = await authFetch(
    `/api/encompass/workflows?workflow_id=${workflow_id}`
  )
  if (res.ok) {
    const data = (await res.json()) as unknown
    const parsed = workflowDetailsResponseSchema.parse(data)
    return parsed
  }
}

export { fetchWorkflow }
