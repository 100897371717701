import { z } from 'zod'

const workflowOverrideNodePostSchema = z.object({
  version: z.literal(1),
  state: z.record(z.unknown()),
  node_id: z.string(),
})

const workflowOverrideNodeResponseSchema = z.discriminatedUnion('success', [
  z.object({
    message: z.object({
      id: z.string(),
      result: z.string(),
      status: z.string(),
    }),
    success: z.literal(true),
  }),
  z.object({
    message: z.string(),
    success: z.literal(false),
  }),
])

type WorkflowOverrideNodePostType = z.infer<
  typeof workflowOverrideNodePostSchema
>

export {
  workflowOverrideNodeResponseSchema,
  workflowOverrideNodePostSchema,
  type WorkflowOverrideNodePostType,
}
