import { Icon } from '@components'
import { Link, Outlet, useSearch } from '@tanstack/react-router'

export default function WorkflowsLayout() {
  const search = useSearch({
    from: '/authenticated/additional-services/workflows',
  })
  const backDisabled = search?.disableBack === true

  return (
    <div className="flex h-full flex-col">
      <header className="mb-6 mt-8 flex flex-col items-start gap-4">
        {!backDisabled && (
          <Link
            className="flex w-auto items-center gap-1 text-sm text-primary"
            to="/additional-services/"
          >
            <Icon type="leftArrow" className="h-6 w-6" />
            Back to Additional Services
          </Link>
        )}
        <h1 className="text-2xl text-slate-800">Automation Intelligence</h1>
      </header>
      <div className="flex w-full flex-col items-center justify-start">
        <Outlet />
      </div>
    </div>
  )
}
