import { useContext } from 'react'

import { HostContext } from '@utils'
import { Button } from '.'

type Props = {
  variant?: 'outlined' | 'primary' | 'text'
  size?: 'base' | 'md' | 'sm'
}

export default function CloseButton({ variant = 'text', size }: Props) {
  const host = useContext(HostContext)

  return (
    <Button
      onClick={e => {
        e.preventDefault()
        void host.close()
      }}
      size={size}
      variant={variant}
    >
      Close
    </Button>
  )
}
