import type { HTMLAttributes, MouseEvent, ReactNode } from 'react'

type Variant = 'primary' | 'outlined' | 'text'
type Size = 'none' | 'sm' | 'md' | 'base'
type Props = {
  variant?: Variant
  size?: Size
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  className?: HTMLAttributes<HTMLButtonElement>['className']
  children?: ReactNode
  disabled?: boolean
}

const sizeMap: Record<Size, string> = {
  none: 'p-0',
  sm: 'p-3 py-2',
  md: 'p-4 py-3',
  base: 'p-10 py-4',
}

const backgroundMap: Record<Variant, string> = {
  primary: 'disabled:bg-gray-100 bg-primary',
  outlined: 'bg-transparent',
  text: 'bg-transparent',
}
const bgTransitionsMap: Record<Variant, string> = {
  primary:
    'hover:disabled:bg-gray-100 hover:bg-primary-hover active:bg-primary-active active:shadow-transparent',
  outlined:
    'disabled:bg-transparent hover:bg-opacity-10 hover:bg-primary-hover active:bg-primary-active active:bg-opacity-10',
  text: 'disabled:bg-transparent hover:bg-gray-100 active:bg-gray-50',
}
const textColorMap: Record<Variant, string> = {
  primary: 'disabled:text-gray-400 text-white',
  outlined: 'disabled:text-gray-400 text-primary',
  text: 'disabled:text-gray-400 text-primary',
}
const shadowMap: Record<Variant, string> = {
  primary: 'disabled:shadow-none shadow-md shadow-gray-300',
  outlined: 'disabled:ring-gray-200 ring-primary ring-1',
  text: '',
}

export default function Button({
  variant = 'primary',
  size = 'base',
  onClick,
  children,
  className,
  disabled = false,
}: Props) {
  return (
    <button
      data-testid="button"
      onClick={onClick}
      className={`rounded ${backgroundMap[variant]} ${
        sizeMap[size]
      } text-xs font-medium uppercase ${textColorMap[variant]} ${shadowMap[variant]} transition ${
        bgTransitionsMap[variant]
      } ${className ? className : ''}`}
      disabled={disabled}
    >
      {children}
    </button>
  )
}
