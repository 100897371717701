import { getOrigin } from '@services/getOrigin'
import type { HostContextType } from '@utils'

export class Store {
  private transactionId: string | undefined
  private host: HostContextType | null = null
  private origin: Awaited<ReturnType<typeof getOrigin>> | null = null

  public setHost(host: HostContextType) {
    this.host = host
  }

  public setOrigin(origin: Awaited<ReturnType<typeof getOrigin>>) {
    this.origin = origin
  }

  public setTransactionId(id: string) {
    this.transactionId = id
  }

  public getState() {
    return {
      host: this.host!,
      origin: this.origin!,
      transactionId: this.transactionId,
    }
  }
}
