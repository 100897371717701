import { authFetch } from './authFetch'
import { z } from 'zod'
import { orderStatusSchema } from '@types'

const orderStatusResponseSchema = z.discriminatedUnion('success', [
  z.object({
    message: orderStatusSchema,
    success: z.literal(true),
  }),
  z.object({
    // TODO: verify if this is the correct type for the error message
    message: z.string(),
    success: z.literal(false),
  }),
])

export type OrderStatusResponse = z.infer<typeof orderStatusResponseSchema>

export const fetchOrderStatus = async (order_id: string) => {
  console.log('Fetching order ' + order_id + ' status')
  const res = await authFetch(`/api/order/${order_id}`)
  const data = (await res.json()) as unknown

  return orderStatusResponseSchema.parse(data).message
}
