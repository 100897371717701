import type { FieldPropsType } from '@types'

// Section Buckets
const BORROWER_FIELDS = [
  'borrower.dob',
  'borrower.last_4_ssn',
  'borrower.phone_number',
  'borrower.fico_score',
  'borrower.email',
  'custom.borrower_email_enabled',
  'custom.borrower_sms_enabled',
  'borrower.has_coborrowers',
  'borrower.ssn',
  'borrower.some_time',
  'borrower.documents',
  'borrower.files',
]
const LOAN_FIELDS = [
  'loan_sub_type',
  'sub_loan_type',
  'loan_number',
  'custom.loan_number',
  'loan_term',
  'subject_property.property_type',
  'subject_property.parcel_id',
  'mortgage_type',
  'lien_position',
  'loan_type',
  'workflow_loan_type',
  'loan_amount',
  'first_mortgage_balance',
  'service.decision_bundle.product_property_type',
]
const CONTACT_FIELDS = [
  'inspector_contacts',
  'service.pcr.special_instructions',
  'service.title.notes',
  'service.appraisal.comments',
]
const NO_SECTION_FIELDS = ['borrowers']

// Section Helpers

const determineSection = (fieldName: string): SectionEnum | null => {
  if (BORROWER_FIELDS.some(n => fieldName.includes(n))) return 'BORROWER_FIELDS'
  else if (LOAN_FIELDS.includes(fieldName)) return 'LOAN_FIELDS'
  else if (CONTACT_FIELDS.includes(fieldName)) return 'CONTACT_FIELDS'
  else if (NO_SECTION_FIELDS.includes(fieldName)) return 'NO_SECTION_FIELDS'
  return 'DEFAULT_FIELDS'
}

// Fields are placed into sections
const buildSections = (data?: FieldPropsType[]) => {
  if (data == null) return []
  // data here will have required & optional fields
  // do we care at all about checking those?

  const sections: Record<SectionEnum, SectionType> = {
    BORROWER_FIELDS: {
      title: 'Borrower(s)',
      fields: [],
    },
    LOAN_FIELDS: {
      title: 'Loan Info',
      fields: [],
    },
    CONTACT_FIELDS: {
      title: 'Who should the inspector contact?',
      fields: [],
    },
    NO_SECTION_FIELDS: {
      title: '__no_section__',
      fields: [],
    },
    DEFAULT_FIELDS: {
      title: 'Additional Info',
      fields: [],
    },
  }

  for (const field of data) {
    const targetSection = determineSection(field.name || '')
    if (targetSection && !sections[targetSection].fields.includes(field.name)) {
      sections[targetSection].fields.push(field.name || '')
    }
  }

  // only return sections with fields
  const results: SectionType[] = []
  if (sections.BORROWER_FIELDS.fields.length) {
    results.push(sections.BORROWER_FIELDS)
  }
  if (sections.LOAN_FIELDS.fields.length) {
    results.push(sections.LOAN_FIELDS)
  }
  if (sections.CONTACT_FIELDS.fields.length) {
    results.push(sections.CONTACT_FIELDS)
  }
  if (sections.NO_SECTION_FIELDS.fields.length) {
    results.push(sections.NO_SECTION_FIELDS)
  }
  if (sections.DEFAULT_FIELDS.fields.length) {
    results.push(sections.DEFAULT_FIELDS)
  }
  return results
}

// Section Types
type SectionEnum =
  | 'BORROWER_FIELDS'
  | 'LOAN_FIELDS'
  | 'CONTACT_FIELDS'
  | 'NO_SECTION_FIELDS'
  | 'DEFAULT_FIELDS'
type SectionType = {
  title: string
  fields: string[]
}

export { buildSections }
export type { SectionEnum, SectionType }
