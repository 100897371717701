export function getNestedValue<T>(
  obj: Record<string, unknown>,
  key: string
): T | undefined {
  const keys = key.split('.')
  let result: Record<string, unknown> | undefined = obj

  for (const currentKey of keys) {
    if (result && typeof result === 'object' && currentKey in result) {
      result = result[currentKey] as Record<string, unknown>
    } else {
      return undefined
    }
  }

  return result as T | undefined
}
