import { z } from 'zod'
import { statusEnumSchema } from './covianceStatuses'

const runningFlowsSchema = z.object({
  status: statusEnumSchema,
  date_started: z.coerce.date(),
  id: z.string(),
  source_id: z.string().optional(),
})

type RunningFlowsType = z.infer<typeof runningFlowsSchema>

const workflowSchema = z.object({
  alias: z.string().nullable(),
  content: z.string().nullable(),
  date_created: z.coerce.date(),
  description: z.string(),
  enabled: z.boolean(),
  id: z.string(),
  name: z.string(),
  published: z.boolean(),
  running_flows: z.array(runningFlowsSchema),
  available_to_order: z.boolean(),
})

const workflowsSchema = z.array(workflowSchema).default([])

type WorkflowsType = z.infer<typeof workflowsSchema>
type WorkflowType = z.infer<typeof workflowSchema>

const workflowsResponseSchema = z.discriminatedUnion('success', [
  z.object({
    message: z.union([z.string(), workflowsSchema]),
    success: z.literal(false),
  }),
  z.object({
    message: workflowsSchema,
    success: z.literal(true),
  }),
])

export {
  workflowSchema,
  workflowsSchema,
  runningFlowsSchema,
  workflowsResponseSchema,
}
export type { WorkflowType, WorkflowsType, RunningFlowsType }
