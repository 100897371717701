import { CloseButton } from '@components'
import InteractionPanel from './InteractivePanel'
import { filterWorkflowNodes, usePollWorkflowStatus } from './_helpers'
import { useEffect, useMemo, useState } from 'react'
import type { WorkflowInteractiveType, WorkflowStatusType } from '@types'
import WorkflowActionPanel from './WorkflowActionPanel'
import WorkflowStatusRow from './WorkflowStatusRow'
import { workflowStatusRoute } from '../../router/authenticated/additionalServicesRoute/workflowRoutes'

export default function WorkflowStatus() {
  const { data, info } = workflowStatusRoute.useLoaderData()

  // Validate route data
  // TODO: move to helper & standardize & test
  if (typeof data === 'string') throw new Error(data)
  if (data == null) throw new Error('data not found...')
  if (data instanceof Response || info instanceof Response) {
    throw new Error('500')
  }

  // Add state to control re-rendering from invalidation
  const [{ data: d, info: i }, setData] = useState<{
    data: WorkflowStatusType
    info?: WorkflowInteractiveType
  }>({ data, info })

  useEffect(() => {
    setData({ data, info })
  }, [data, info])

  usePollWorkflowStatus(setData, data, info)

  const [nodes, nodeDetails] = useMemo(() => {
    const filtered = filterWorkflowNodes(d.nodes)
    const details = d.nodes?.find(n => n.id === i?.node_id)
    return [filtered, details]
  }, [d.nodes, i?.node_id])

  return (
    <>
      {/* Header */}
      <div className="w-full">
        <h2 className="mb-6 text-lg font-medium text-secondary">{d.name}</h2>

        <WorkflowActionPanel data={d} />
        <InteractionPanel
          setData={setData}
          workflow_id={data.id}
          details={nodeDetails}
          info={i}
        />
      </div>

      {/* Status rows (nodes) */}
      <ul className="flex w-full flex-col gap-4">
        {nodes?.map(node => (
          <WorkflowStatusRow nodeData={node} flowData={d} key={node.id} />
        ))}
      </ul>

      {/* Footer */}
      <div className="my-12 mr-auto">
        <CloseButton size="md" variant="primary" />
      </div>
    </>
  )
}
