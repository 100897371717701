import { authFetch } from '@services/authFetch'
import { workflowInteractiveResponseSchema } from '@types'

// TODO: clean up error cases
export const fetchInteractiveNode = async (flow_id: string) => {
  console.log('Fetching Workflow Interactive')

  const res = await authFetch(`/api/encompass/lia/interactive/${flow_id}/info`)
  if (res.ok) {
    const data = (await res.json()) as { message: unknown; success: boolean }
    if (!data.success) {
      return undefined
    }
    const parsed = workflowInteractiveResponseSchema.parse(data).message
    return parsed
  } else {
    return res
  }
}
