import { createRoute } from '@tanstack/react-router'

import { getOrigin } from '@services/getOrigin'
import { getTransaction } from '@services/getTransaction'
import { fetchOrderStatus } from '@services/orderStatus'

import { Store, hostLoader } from '../_helpers'
import { rootRoute } from '../rootRoute'
import { Pending } from '@components'

const authStore = new Store()

export const authenticatedRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: 'authenticated',
  wrapInSuspense: true,
  beforeLoad: async () => {
    const { host, origin } = authStore.getState()

    if (host == null) {
      console.debug('Before Host')
      authStore.setHost(await hostLoader())
      console.debug('After Host')
    }

    if (origin == null) {
      const { host } = authStore.getState()
      if (!host) throw new Error('An error has occured with host')

      const {
        id: originId,
        partnerAccessToken,
        transactionId,
      } = await host.getTransactionOrigin()

      console.debug('Before Origin')
      const origin = await getOrigin(originId, partnerAccessToken)
      if (!origin) throw new Error('An error has occcured with origin')
      console.debug('After Origin')

      authStore.setOrigin(origin)
      authStore.setTransactionId(transactionId)
    }
  },
  loader: () => {
    const state = authStore.getState()
    return state
  },
  pendingComponent: Pending,
}).lazy(() => import('./_layout').then(d => d.Route))

export const orderStatusRoute = createRoute({
  getParentRoute: () => authenticatedRoute,
  path: '/order-status/$transactionId',
  loader: async ({ params, navigate }) => {
    const transaction = await getTransaction(params.transactionId)
    if (!transaction)
      throw new Error('Something has gone wrong getting your transaction')

    // if we have a reference number & its not a 'Failed Order',
    if (
      transaction.response?.referenceNumber &&
      transaction.response.referenceNumber !== 'Failed Order'
    )
      // then we can fetch the order status and show it to the user
      return await fetchOrderStatus(transaction.response.referenceNumber)

    // if we have response errors then we can show them to the user
    if (transaction.response?.errors) {
      const order =
        transaction?.request?.options?.category || transaction?.request?.type
      await navigate({
        to: '/submit',
        search: {
          success: false,
          order: order || '',
          errors: transaction.response.errors,
        },
      })
      return
    }

    // If no reference number & no errors then we are still waiting for encompass...
    await navigate({
      to: '/submit',
      search: {
        success: false,
        order: transaction?.request?.options?.category || '',
        info: 'Please try again in a few minutes.',
      },
    })
    return
  },
}).lazy(() => import('../../pages/OrderStatus').then(d => d.Route))

// Only used in local development
export const devRoute = createRoute({
  getParentRoute: () => authenticatedRoute,
  path: '/dev',
  wrapInSuspense: true,
  pendingComponent: Pending,
}).lazy(() => import('../../pages/Products').then(d => d.Route))
