import type { ConditionalFieldsType } from '@types'

const INSPECTOR_CONTACTS = [
  'inspector_contacts.relationship_other',
  'inspector_contacts.relationship',
  'inspector_contacts.first_name',
  'inspector_contacts.last_name',
  'inspector_contacts.email',
  'inspector_contacts.phone',
]

export const inspectorContactsConditionalProps: ConditionalFieldsType = {
  'inspector_contacts.relationship': {
    condition: fields => {
      // Explicitly check if all the fields are present
      let relationship_other = false,
        relationship = false,
        first_name = false,
        last_name = false,
        email = false,
        phone = false

      for (const field of fields) {
        switch (field.name) {
          case INSPECTOR_CONTACTS[0]:
            relationship_other = true
            break
          case INSPECTOR_CONTACTS[1]:
            relationship = true
            break
          case INSPECTOR_CONTACTS[2]:
            first_name = true
            break
          case INSPECTOR_CONTACTS[3]:
            last_name = true
            break
          case INSPECTOR_CONTACTS[4]:
            email = true
            break
          case INSPECTOR_CONTACTS[5]:
            phone = true
            break
          default:
            break
        }
      }

      return (
        relationship_other &&
        relationship &&
        first_name &&
        last_name &&
        email &&
        phone
      )
    },
    removeFields: INSPECTOR_CONTACTS,
    injectFields: ['inspector_contacts'],
  },
}
