import { authFetch } from '@services/authFetch'
import { workflowCancelResponseSchema } from '@types'

export const postCancel = async (workflow_id: string) => {
  console.log('Posting Workflow Cancel', workflow_id)
  const res = await authFetch(
    `/api/encompass/lia/flows/${workflow_id}/cancel-flow`,
    { method: 'POST' }
  )

  if (res.ok) {
    const data = (await res.json()) as unknown
    const parsed = workflowCancelResponseSchema.parse(data)

    return parsed
  }

  return res
}
