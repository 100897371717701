import type { AdditionalPropsType } from '@types'

export const propertyTypeProps: AdditionalPropsType = {
  options: [
    {
      label: 'Residential (1-4 Units)',
      name: 'residential',
    },
    {
      label: 'Commerical',
      name: 'commercial',
    },
    {
      label: 'Auto',
      name: 'auto',
    },
    {
      label: 'Industrial',
      name: 'industrial',
    },
    {
      label: 'Land',
      name: 'land',
    },
    {
      label: 'Other',
      name: 'other',
    },
  ],
}
