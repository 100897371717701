import { useContext, useState } from 'react'
import { useNavigate } from '@tanstack/react-router'
import { FormProvider, useForm } from 'react-hook-form'

import { Button, CloseButton } from '@components'
import { postWorkflow } from '@services/workflows'
import { HostContext, objectToDot, prettify } from '@utils'
import type { ConditionalFieldsType, WorkflowDetailsType } from '@types'

import { useFormFields, RawFieldsProvider } from '../_helpers'

import { buildSections } from './_helpers'
import { AlertDialog } from './AlertDialog'
import { Sections } from './Sections'

const conditionalFields: ConditionalFieldsType = {
  loan_type: {
    condition: () => true,
    injectFields: ['workflow_loan_type'],
    removeFields: ['loan_type', 'loan_sub_type', 'loan_sub_type_other'],
  },
}

type FormType = Record<string, unknown>

const buildPostBody = (
  flow_id: string,
  origin_id: string,
  partner_access_token: string,
  data: FormType | null
) => {
  const body = {
    flow_id,
    origin_id,
    partner_access_token,
    state: data,
  }

  return body
}

export default function WorkflowForm({
  fieldData,
  flow_id,
  title,
}: {
  fieldData: WorkflowDetailsType
  flow_id: string
  title: string
}) {
  const [alert, setAlert] = useState(false)
  const { getTransactionOrigin } = useContext(HostContext)

  const navigate = useNavigate()

  const methods = useForm<FormType>()
  const [fields, onSubmit, rawFields] = useFormFields({
    fieldData,
    methods,
    conditionalFields,
    onSubmit: async values => {
      const { id, partnerAccessToken } = await getTransactionOrigin()

      const body = buildPostBody(flow_id, id, partnerAccessToken, values)

      const fieldErrors = await postWorkflow(body)

      // TODO: split to fn
      if (fieldErrors && !(fieldErrors instanceof Response)) {
        if (fieldErrors.success) {
          // No errors
          // TODO: Make sure to remove before production
          console.info('No Form Errors, proceeding with submission\n', {
            values,
            body,
          })

          await navigate({
            to: '/submit',
            search: { success: true, workflow: title },
          })
          return
        } else if (typeof fieldErrors.message == 'string') {
          console.warn(
            'Unable to start workflow. Error is string:\n',
            fieldErrors.message
          )
          // TODO: display fieldErrors.message to the user??
          // currently we are just showing a static error modal
          setAlert(true)
          return
        } else {
          console.log('Unable to start workflow:\n', fieldErrors.message)
          // Has errors

          // TODO: we can get rid of this when we start looking at origin data
          const values = objectToDot(methods.getValues()) ?? {}
          const missingFields: {
            description: string
          }[] = []

          for (const [field, value] of Object.entries(fieldErrors.message)) {
            const [message] = value

            if (field in values) {
              methods.setError(field, { message })
            } else {
              missingFields.push({
                description: `${prettify(field)}: ${message}`,
              })
            }
          }

          if (Object.keys(missingFields).length) {
            await navigate({
              to: '/submit',
              search: {
                success: false,
                workflow: title,
                errors: missingFields,
              },
            })
          }

          return
        }
      }
      // Show user the alert
      if (fieldErrors?.status === 500) {
        console.error('Server error')
        setAlert(true)
        return
      }

      console.error('This should never happen')
      throw new Error('500')
    },
  })

  const sections = buildSections(fields)

  return (
    <RawFieldsProvider data={rawFields}>
      <FormProvider {...methods}>
        <form className="grid-col mb-20 grid w-full gap-4" onSubmit={onSubmit}>
          <Sections sections={sections} data={fields} />
          {/* Footer */}
          <div className="flex gap-4">
            <Button size="base" disabled={alert}>
              <input
                type="submit"
                value="Submit"
                disabled={alert}
                className="cursor-pointer uppercase"
              />
            </Button>
            <CloseButton />
          </div>
        </form>
        <AlertDialog onClose={() => setAlert(false)} isOpen={alert} />
      </FormProvider>
    </RawFieldsProvider>
  )
}
