import { Navigate } from '@tanstack/react-router'
import { useContext } from 'react'
import { OriginContext } from '@utils'

export default function WorkflowsRedirect() {
  const origin = useContext(OriginContext)
  if (origin == null) throw new Error('Origin not found...')
  if (origin?.missingFields) {
    return (
      <Navigate
        to="/submit"
        search={{
          success: false,
          workflow: origin?.loanNumber,
          errors: origin.missingFields,
        }}
      />
    )
  }

  return (
    <Navigate
      to="/additional-services/workflows/$loanNumber"
      params={{ loanNumber: origin.entityId }}
      search={search => search}
    />
  )
}
