import { z } from 'zod'
import { authFetch } from './authFetch'

const getOriginLoanDataSchema = z.record(z.unknown()).optional().default({})

const getOriginSchema = z.object({
  borrowerDisplayName: z.string().nullable().default(''),
  loanNumber: z.string(),
  entityId: z.string(),
  missingFields: z.array(z.object({ description: z.string() })).optional(),
  loan: getOriginLoanDataSchema, // Is this optional? or have a default value?
})
const getOrigin = async (originId: string, partnerAccessToken: string) => {
  console.debug('Fetching getOrigin')
  const response = await authFetch(
    `/api/encompass/origins/${originId}?partner_access_token=${partnerAccessToken}`
  )
  if (response.ok) {
    const data = (await response.json()) as { success: boolean }
    // When fetch throws an error
    if (data.success === false) {
      return undefined
    }
    const parsed = getOriginSchema.parse(data)
    return parsed
  }

  return undefined
}

export { getOrigin }
