import type { ConditionalFieldsType } from '@types'

export const borrowersConditionalProps: ConditionalFieldsType = {
  borrowers: {
    condition: fields => {
      let hasBorrowerDob = false,
        hasBorrowerSsn = false,
        hasCoborrowerDob = false,
        hasCoborrowerSsn = false
      for (const field of fields) {
        if (field.name === 'borrower.dob') hasBorrowerDob = true
        if (field.name === 'borrower.ssn') hasBorrowerSsn = true
        if (field.name === 'coborrower.dob') hasCoborrowerDob = true
        if (field.name === 'coborrower.ssn') hasCoborrowerSsn = true
      }

      return (
        hasBorrowerDob && hasBorrowerSsn && hasCoborrowerDob && hasCoborrowerSsn
      )
    },
    removeFields: [
      'borrower.dob',
      'borrower.ssn',
      'coborrower.dob',
      'coborrower.ssn',
    ],
  },
}
