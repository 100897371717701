import type { ConditionalFieldsType } from '@types'

export { borrowersConditionalProps } from './borrowers'
export { inspectorContactsConditionalProps } from './inspectorContacts'

export const mortgageTypeConditionalProps: ConditionalFieldsType = {
  mortgage_type: {
    condition: () => true,
    removeFields: ['mortgage_type'],
  },
}
