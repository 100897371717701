import type { RegisterOptions } from 'react-hook-form'
import { z } from 'zod'

/** Field Validation Types */
export const fieldClassEnumSchema = z.enum([
  'String',
  'Boolean',
  'Integer',
  'Float',
  'Date',
  'Phone',
  'Email',
  'File',
  'Document',
  'DateTime',
  'Ssn',
  'unknown',
])
export type FieldClassEnum = z.infer<typeof fieldClassEnumSchema>

export const fieldPropsSchema = z.object({
  name: z.string(),
  field_class: fieldClassEnumSchema,
  attribute: z.string().optional(),
  type: z.string().optional(),
  label: z.string().optional(),
  required: z.boolean().optional(),
  disabled: z.boolean().optional(),
})
export type FieldPropsType = z.infer<typeof fieldPropsSchema>

export type FieldOptionsType = {
  label?: string // <- description
  name: string // <- this is an id in our db
}

export type AdditionalPropsType = {
  usePii?: boolean
  options?: FieldOptionsType[]
  size?: 'w-full' | 'w-1/2'
  registerOptions?: RegisterOptions
}

export type FieldComponentPropsType = {
  field: FieldPropsType
} & AdditionalPropsType
