type VariantEnum = 'normal' | 'static'

const styleMap: Record<VariantEnum, string> = {
  normal: 'absolute bottom-1/2 left-4 -mx-3 translate-y-1/2 p-1 text-sm',
  static: 'text-sm',
}

export default function FieldLabel({
  label,
  required,
  touched,
  variant = 'normal',
  onClick,
}: {
  label: string
  required?: boolean
  touched?: boolean
  variant?: VariantEnum
  onClick?: () => void
}) {
  return (
    <>
      <label
        onClick={onClick}
        className={`pointer-events-none ${styleMap[variant]} w-fit min-w-[100px] capitalize leading-none text-gray-600 transition-all duration-75 ${touched ? 'bottom-full' : ''} group-data-[active=true]:bottom-full group-data-[empty=false]:bottom-full group-data-[active=true]:min-w-0 group-data-[empty=false]:min-w-0 group-data-[active=true]:text-[10px] group-data-[empty=false]:text-[10px] group-data-[invalid=true]:text-red-500`}
      >
        {variant === 'normal' && (
          <span className="data-group-[empty=false]:h-[4px] data-group-[empty=false]:top-[calc(50%-2px)] absolute left-0 top-[calc(50%-12px)] h-6 w-full bg-white"></span>
        )}
        <span className="relative">
          {label}
          {required ? ' *' : ''}
        </span>
      </label>
    </>
  )
}
