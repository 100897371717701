import type { FieldPropsType } from '@types'
import { objectToDot, processFieldValues } from '@utils'

/**
 * Make sure to convert data types based on field_class
 * @param transformed - is mutated
 * @param data - is not mutated
 */
const processFieldClasses = (
  transformed: Record<string, unknown> | null,
  data: FieldPropsType[]
) => {
  // make sure to convert data types based on field_class
  for (const field of data) {
    if (transformed && field.name in transformed) {
      transformed[field.name] = processFieldValues(
        transformed[field.name],
        field
      )
    }
  }
}

/**
 * Process form data is needed for the following:
 *
 * - cleans up front-end only data
 * - converts form data to dot notation
 * - converts data types based on field_class
 */
const processFormData = <T extends Record<string, unknown>>(
  _form: T,
  fields: FieldPropsType[]
): T => {
  const form = structuredClone(_form)
  if ('borrowers' in form && Array.isArray(form.borrowers)) {
    // Remove data if not reflected in 'borrowers'
    if (!form.borrowers?.includes('borrower')) delete form.borrower
    if (!form.borrowers?.includes('coborrower')) delete form.coborrower
  }

  const hasInspector = 'inspector_contacts' in form
  if (hasInspector && form.inspector_contacts) {
    const f = form as T & { inspector_contacts: unknown }
    // Make sure inspector_contacts is an array
    if (!Array.isArray(form.inspector_contacts)) {
      f.inspector_contacts = [form.inspector_contacts]
    }
    // Loop through inspector_contacts and always set relationship_other to null
    for (const contact of form.inspector_contacts as Record<
      string,
      unknown
    >[]) {
      contact.relationship_other = null
    }
  }
  if ('bureaus' in form && Array.isArray(form.bureaus)) {
    // Remove 'all' from bureaus if present
    const f = form as T & { bureaus: unknown[] }
    f.bureaus = form.bureaus.filter(el => el !== 'all')
  }
  if ('lien_position' in form) {
    const f = form as T & {
      lien_position: unknown
      first_mortgage_balance: unknown
    }
    // Convert lien_position to a interger
    if (f.lien_position === '1st Lien') {
      f['lien_position'] = 1
      f['first_mortgage_balance'] = '0'
    } else {
      f['lien_position'] = 2
    }
  }

  // Default values to always include
  const f = form as T & { first_mortgage_balance: unknown }
  f['first_mortgage_balance'] = form?.first_mortgage_balance || '0'

  const transformed = objectToDot(form)
  processFieldClasses(transformed, fields)

  if (!transformed)
    throw new Error('Failed to transform form data to dot notation.')

  for (const f of fields) {
    console.info('Processing field', f)
    if (f.disabled && f.name in transformed) {
      delete transformed[f.name]
    }
  }
  return transformed as T
}

export { processFormData }
