import { useState } from 'react'
import { useRouter } from '@tanstack/react-router'
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form'
import exclaimSrc from '@assets/exclaim-circle.svg'

import { Button, Field, Pending } from '@components'
import type {
  NodeType,
  InteractiveAttributeType,
  WorkflowInteractiveType,
} from '@types'
import { postCancel, postInteractiveNode } from '@services/workflows'
import { objectToDot } from '@utils'
import type { WorkflowStatusStateType } from './_helpers'

const DISABLED_STATUSES: NodeType['status'][] = [
  'complete',
  'canceled',
  'Complete',
  'stopped',
]

/**
 * Interaction Panel - This handles the interactive node fields
 */
function InteractionPanel({
  setData,
  details,
  info,
  workflow_id,
}: {
  setData: React.Dispatch<React.SetStateAction<WorkflowStatusStateType>>
  details?: NodeType
  info?: WorkflowInteractiveType
  workflow_id: string
}) {
  const [loading, setLoading] = useState(false)
  const router = useRouter()
  const methods = useForm()

  const onSubmit: SubmitHandler<Record<string, unknown>> = async values => {
    const transformed = objectToDot(values)
    if (transformed === null) throw new Error('Failed to transform values')

    if (info?.attributes) {
      // make sure to convert data types based on field_class
      for (const field of info.attributes) {
        if (
          transformed &&
          field.name in transformed &&
          field.field_class === 'Boolean'
        ) {
          transformed[field.name] = transformed[field.name] === 'true'
        }
      }
    }
    setLoading(true)
    const res = await postInteractiveNode(
      workflow_id,
      transformed,
      details?.id || info?.node_id || ''
    )
    if (res instanceof Response) {
      console.info('InteractiveNode is a reponse', res)
      throw new Error(
        'Error with ' +
          (res instanceof Response
            ? `status code - ${res.status}`
            : 'No Response')
      )
    } else if (res.success) {
      console.info('No errors posting interactive node data', {
        values,
        transformed,
      })
      setData(prev => {
        return {
          ...prev,
          info: undefined,
        }
      })
      router.invalidate()
    } else {
      // There was a validation error
      if (typeof res.message !== 'string') {
        for (const [field, value] of Object.entries(res.message)) {
          if (Array.isArray(value)) {
            const [message] = value // The error message
            methods.setError(field, { message })
          }
        }
      } else {
        console.error('Error posting interactive node data', res.message)
      }
    }
    setLoading(false)
  }

  const cancelHandler = async (id: string) => {
    const res = await postCancel(id)

    if (res instanceof Response)
      throw new Error('Error with status code ' + res.status)

    if (res.success) {
      console.info('No errors posting cancel node data')
      if (info)
        setData(prev => ({
          data: { ...prev.data, status: 'canceled' },
          info: undefined,
        }))
      router.invalidate()
    } else {
      throw new Error(res.message)
    }
  }

  if (!info?.attributes.length) {
    return null
  }

  return (
    <div className="relative w-full">
      {loading && <Pending position="absolute" opacity={50} />}
      <div className="mb-4 w-full rounded bg-status-yellow_light p-4 px-6">
        <span className="flex items-center gap-3">
          <img src={exclaimSrc} className="h-6 w-6" />
          <h3 className="text-sm font-bold">
            <u>{details?.description}</u> requires additional information
          </h3>
        </span>
      </div>
      <FormProvider {...methods}>
        <form
          onSubmit={e => {
            setLoading(true)
            e.preventDefault()
            void methods
              .handleSubmit(onSubmit)()
              .finally(() => setLoading(false))
          }}
        >
          {/* Interactive Node Fields */}
          <div className="mb-4 grid grid-cols-2 gap-4 border-gray-500">
            <InteractiveNodeAttributes attributes={info?.attributes} />

            {/* Interaction Panel Controls */}
            <div className="mb-8 flex gap-4 text-gray-950">
              <Button className="leading-[1.1]">
                <input
                  type="submit"
                  className="cursor-pointer uppercase"
                  value="continue"
                />
              </Button>
              <Button
                variant="text"
                size="md"
                className="text-red-500"
                disabled={
                  loading &&
                  details &&
                  DISABLED_STATUSES.includes(details.status)
                }
                onClick={e => {
                  e.preventDefault()
                  void cancelHandler(details?.id || '')
                }}
              >
                Stop Workflow
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

function InteractiveNodeAttributes({
  attributes,
}: {
  attributes?: InteractiveAttributeType[]
}) {
  if (!attributes) return null
  return attributes.map(a => {
    return (
      <Field
        key={a.name}
        field={{
          field_class: a.field_class,
          name: a.name,
          label: a.label ?? undefined,
          required: a.required,
          type: a?.type,
        }}
        registerOptions={{
          shouldUnregister: true,
        }}
        options={(a.options ?? []).map(o => ({
          ...o,
          name: o.value,
        }))}
      />
    )
  })
}

export default InteractionPanel
