import { authFetch } from '@services/authFetch'
import { fieldErrorsResponseSchema } from '@types'
import { objectToDot } from '@utils'

export const postWorkflow = async (formData: Record<string, unknown>) => {
  console.log('Posting Workflow Details')
  const res = await authFetch('/api/encompass/workflows', {
    method: 'POST',
    body: JSON.stringify(formData),
  })

  if (res.ok) {
    const data = (await res.json()) as { message: unknown; success: boolean }

    if (data.message && typeof data.message === 'object') {
      // if res has state in it, then success: true
      if (!('state' in data.message)) {
        const transformed = objectToDot(data.message)
        data['message'] = transformed ?? data.message
      }
    }

    const parsed = fieldErrorsResponseSchema.parse(data)
    return parsed
  } else if (res.status === 500) {
    return res
  }
}
