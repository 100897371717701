import type { FieldPropsType } from '@types'
import { processFieldValues } from '@utils'
import type {
  FieldValues,
  Path,
  PathValue,
  UseFormReturn,
} from 'react-hook-form'

/**
 * ### checkLoanData
 *
 * When you need to check the loan data after the form has already been initialized.
 *
 * If there is any loan data with the same name as the form field, then the field will be disabled.
 *
 * @requires useForm from 'react-hook-form'
 * @param fields The form fields
 * @param loanData The loan data from origin
 * @param methods The react-hook-form methods
 * @returns New fields that have been disabled (if they have loan data)
 */
export function checkLoanData<T extends FieldValues>(
  fields: FieldPropsType[],
  loanData: T | null,
  methods?: UseFormReturn<T>
) {
  if (loanData === null) return fields

  const next = [...fields]
  for (const ent of Object.entries<T>(loanData)) {
    const [key, v] = ent as [Path<T>, PathValue<T, Path<T>>]
    const field = next?.find(i => {
      return i.name === key
    })

    if (field && methods) {
      const value = processFieldValues(v, field)
      console.info(`registering\n`, { [key]: value, originalValue: v })

      methods.setValue(key, value)
    }
  }
  return next
}
