const baseUrl = 'https://' + import.meta.env.VITE_marketplace_hostname

type ProductsEnum = 'flood' | 'title' | 'credit' | 'appraisal'

type ApiEndpoints =
  // Encompass endpoints
  | `/api/encompass/workflows`
  | `/api/encompass/workflows?loan_number=${string}`
  | `/api/encompass/workflows?workflow_id=${string}`
  | `/api/encompass/workflows?loan_number=${string}&source_id=${string}`
  | `/api/encompass/lia/flows/${string}/cancel-flow`
  | `/api/encompass/lia/callback`
  | `/api/encompass/lia/interactive/${string}/info`
  // | `/api/encompass/validate-fields`
  | `/api/encompass/required-fields/${string}/${string}`
  | `/api/encompass/available-vendor-products/${ProductsEnum}/${string}`
  | `/api/encompass/origins/${string}?partner_access_token=${string}`
  | `/api/encompass/transactions/${string}?check_pending_table=${boolean}`
  // Marketplace endpoints
  | `/api/order/${string}`

async function authFetch(url: ApiEndpoints, opts?: RequestInit | undefined) {
  return await fetch(baseUrl + url, {
    mode: 'cors',
    credentials: 'include',
    ...opts,
  }).catch(e => {
    // REFACTOR: we should be more thorough with our error handling
    return new Response(
      JSON.stringify({
        message: (e as Error).message,
        success: false,
      })
    )
  })
}
export { authFetch }
